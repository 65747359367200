import * as actionTypes from "../actions/actionTypes";

const initialState = {
	authenticated: false,
	loading: false,
	data: [],
	success_message: undefined,
	success: false,
	error: undefined,
	rm_loading: false,
};

const roles = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_RESPONSE_ERROR:
			return {
				...state,
				rm_loading: false,
				loading: false,
				success: false,
				error: action.payload.response && action.payload.response.data.error,
			};
		case actionTypes.GET_APPLICATIONS_SUCCESS:
			return {
				...state,
				authenticated: true,
				loading: false,
				success: true,
				success_message: "All Applications.",
				data: action.payload.response.applications,
				pageLinks: action.payload.response.pageLinks,
			};
		case actionTypes.GET_APPLICATION_DATA_SUCCESS:
			return {
				...state,
				authenticated: true,
				loading: false,
				success: true,
				success_message: "Application Data.",
				data: action.payload.response.application,
			};
		case actionTypes.GET_APPLICATION_HISTORY_SUCCESS:
			return {
				...state,
				authenticated: true,
				loading: false,
				success: true,
				success_message: "History Data.",
				data: action.payload.response.history,
			};
		case actionTypes.GET_INTERACTION_HISTORY_SUCCESS:
			return {
				...state,
				authenticated: true,
				loading: false,
				success: true,
				success_message: "Interaction History Data.",
				data: action.payload.response.history,
			};
		case actionTypes.UPDATE_APPLICATIONS_STATUS_SUCCESS:
			return {
				...state,
				authenticated: true,
				loading: false,
				success: true,
				success_message: "Status updated successfully.",
				data: action.payload.response.application,
			};
		case actionTypes.ADD_CONTACT_SUCCESS:
			return {
				...state,
				authenticated: true,
				loading: false,
				success: true,
				success_message: "Contact created successfully.",
				data: action.payload.response.application,
			};
		case actionTypes.CLEAR_ERROR:
			return {
				...state,
				loading: false,
				success: false,
				success_message: undefined,
				error: undefined,
			};
		case actionTypes.STOP_LOADER:
			return { ...state, rm_loading: false, loading: false };
		default:
			return { ...state, loading: false };
	}
};

export default roles;
