import { TableRow } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { Table } from "@material-ui/core";
import NumberFormat from "react-number-format";

//------------------------------------------End of Import ---------------------------------------------------//

export default function OperationalTable(props) {
	return (
		<div style={{ marginTop: "20px" }}>
			<Table id={props.name}>
				<TableHead>
					<TableRow style={{ background: "#cfd8dc" }}>
						<TableCell style={{ fontWeight: "780" }}>Date</TableCell>
						<TableCell style={{ fontWeight: "780" }}>Active</TableCell>
						<TableCell style={{ fontWeight: "780" }}>Rejected</TableCell>
						<TableCell style={{ fontWeight: "780" }}>Progress</TableCell>
					</TableRow>
				</TableHead>
				{props.operationalTableData.map((item, index) => (
					<TableBody>
						{props.tableDataOFNumberAndAmount.number ? (
							<TableRow>
								<TableCell>{item.created_on}</TableCell>
								<TableCell>{item.activen}</TableCell>
								<TableCell>{item.rejected}</TableCell>
								<TableCell>{item.progress}</TableCell>
							</TableRow>
						) : (
							<TableRow>
								<TableCell>{item.created_on}</TableCell>
								<TableCell>
									<NumberFormat
										value={item.approvedAmount}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										renderText={(value) => <div>{value}</div>}
									/>
								</TableCell>
								<TableCell>
									<NumberFormat
										value={item.rejectedAmount}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										renderText={(value) => <div>{value}</div>}
									/>
								</TableCell>
								<TableCell>
									<NumberFormat
										value={item.progressAmount}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										renderText={(value) => <div>{value}</div>}
									/>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				))}
			</Table>
		</div>
	);
}
