import axios from "axios";
import { toast } from "react-toastify";
import { headers } from "../reusable/headers";
import * as actionTypes from "./actionTypes";
axios.defaults.withCredentials = true;

export const LoginSuccess = (response) => {
	return {
		type: actionTypes.LOGIN_SUCCESS,
		payload: {
			response,
		},
	};
};

export const CreateUserSuccess = (response) => {
	return {
		type: actionTypes.CREATE_USER_SUCCESS,
		payload: {
			response,
		},
	};
};

export const UpdateUserSuccess = (response) => {
	return {
		type: actionTypes.UPDATE_USER_SUCCESS,
		payload: {
			response,
		},
	};
};

export const GetAllUsersSuccess = (response) => {
	return {
		type: actionTypes.GET_USERS_SUCCESS,
		payload: {
			response,
		},
	};
};

export async function generateToken(body) {
	try {
		let url = `${process.env.REACT_APP_API_HOST}/V1/admin/generateToken`;
		const response = await axios({
			method: "POST",
			url: url,
			data: body,
			headers: headers(),
		});
		return response.data;
	} catch (error) {
		console.log(error);
		throw new Error(error);
	}
}

export async function verifyTokenExpiry(body) {
	try {
		let url = `${process.env.REACT_APP_API_HOST}/V1/admin/login`;
		const response = await axios({
			method: "POST",
			url: url,
			data: body,
			headers: headers(),
		});
		return response.data;
	} catch (error) {
		console.log(error);
		return false;
	}
}

export const LoginUser = (data) => async (dispatch) => {
	try {
		if (!data.authenticated) {
			if (data.message) {
				console.log("toasty");
				toast.error(data.message);
			}
			return dispatch(createError(data.message));
		}
		toast.success("Successfully Logged In.");
		return dispatch(LoginSuccess(data));
	} catch (e) {
		dispatch(createError(e));
	}
};

export async function resetPassword(body) {
	try {
		let url = `${process.env.REACT_APP_API_HOST}/V1/admin/resetPassword`;
		const response = await axios({
			method: "POST",
			url: url,
			data: body,
			headers: headers(),
		});
		return response.data;
	} catch (error) {
		console.log(error);
		throw new Error(error);
	}
}

export async function forgetPassword(body) {
	try {
		let url = `${process.env.REACT_APP_API_HOST}/V1/admin/forgetPassword`;
		const response = await axios({
			method: "POST",
			url: url,
			data: body,
			headers: headers(),
		});
		return response.data;
	} catch (error) {
		console.log(error);
		throw new Error(error);
	}
}

export const LogoutUser = () => async (dispatch) => {
	try {
		console.log("logout act");
		await axios
			.get(`${process.env.REACT_APP_API_HOST}/V1/admin/logout`, {
				headers: headers(),
			})
			.then((response) => {
				console.log("logout action user");
				window.location.href = `/login`;
				toast.success("Successfully Logged Out.");
				sessionStorage.removeItem("_st");
			})
			.catch((error) => {
				dispatch(createError(error));
			});
	} catch (e) {
		dispatch(createError(e));
	}
};

export const CreateUser = (data) => async (dispatch) => {
	try {
		// data.roleIds = [1,2]
		console.log("create", data);
		await axios
			.post(`${process.env.REACT_APP_API_HOST}/V1/admin/create`, data, {
				headers: headers(),
			})
			.then((response) => {
				console.log("REACT_APP_API_HOST", response.data);
				if (response.data.success === false) {
					toast.error(response.data.message);
					return dispatch(createError(response.data.message));
				}
				toast.success("Successfully Created.");
				return dispatch(CreateUserSuccess(response.data));
			})
			.catch((error) => {
				dispatch(createError(error));
			});
	} catch (e) {
		dispatch(createError(e));
	}
};

export const UpdateUser = (data) => async (dispatch) => {
	try {
		// data.roleIds = [1,2]
		console.log("update", data);
		await axios
			.put(`${process.env.REACT_APP_API_HOST}/V1/admin/update`, data, {
				headers: headers(),
			})
			.then((response) => {
				console.log("REACT_APP_API_HOST", response.data);
				if (response.data.success === false) {
					toast.error(response.data.message);
					return dispatch(createError(response.data.message));
				}
				toast.success("Successfully Updated.");
				return dispatch(UpdateUserSuccess(response.data));
			})
			.catch((error) => {
				dispatch(createError(error));
			});
	} catch (e) {
		dispatch(createError(e));
	}
};

export const GetAllUsers = () => async (dispatch) => {
	try {
		await axios
			.get(`${process.env.REACT_APP_API_HOST}/V1/admin/getUsers`, {
				headers: headers(),
			})
			.then((response) => {
				console.log("get users", response.data);
				if (response.data.success) {
					return dispatch(GetAllUsersSuccess(response.data));
				} else {
					return dispatch(createError("Something went wrong"));
				}
			})
			.catch((error) => {
				dispatch(createError(error));
			});
	} catch (e) {
		dispatch(createError(e));
	}
};

export const DeleteUser = (id) => async (dispatch) => {
	try {
		await axios
			.delete(`${process.env.REACT_APP_API_HOST}/V1/admin/${id}`, {
				headers: headers(),
			})
			.then((response) => {
				if (!response.data.success) {
					toast.error(response.data.message);
					return dispatch(createError("Something went wrong"));
				}
				toast.success(response.data.message);
			})
			.catch((error) => {
				dispatch(createError(error));
			});
	} catch (e) {
		dispatch(createError(e));
	}
};

export const CreateCustomerUser = (data) => async (dispatch) => {
	try {
		console.log("create", data);
		await axios
			.post(
				`${process.env.REACT_APP_API_HOST}/V1/admin/customer/create`,
				data,
				{ headers: headers() }
			)
			.then((response) => {
				console.log("REACT_APP_API_HOST", response.data);
				if (response.data.success === false) {
					toast.error(response.data.message);
					return dispatch(createError(response.data.message));
				}
				toast.success(response.data.message);
				return dispatch(CreateUserSuccess(response.data));
			})
			.catch((error) => {
				dispatch(createError(error));
			});
	} catch (e) {
		dispatch(createError(e));
	}
};

export const UpdateCustomerUser = (data) => async (dispatch) => {
	try {
		console.log("update", data);
		await axios
			.put(`${process.env.REACT_APP_API_HOST}/V1/admin/customer/update`, data, {
				headers: headers(),
			})
			.then((response) => {
				console.log("REACT_APP_API_HOST", response.data);
				if (response.data.success === false) {
					toast.error(response.data.message);
					return dispatch(createError(response.data.message));
				}
				toast.success(response.data.message);
				return dispatch(UpdateUserSuccess(response.data));
			})
			.catch((error) => {
				dispatch(createError(error));
			});
	} catch (e) {
		dispatch(createError(e));
	}
};

export const GetAllCustomerUsers = () => async (dispatch) => {
	try {
		await axios
			.get(`${process.env.REACT_APP_API_HOST}/V1/admin/customer/getCustomers`, {
				headers: headers(),
			})
			.then((response) => {
				console.log("get users", response.data);
				if (response.data.success) {
					return dispatch(GetAllUsersSuccess(response.data));
				} else {
					return dispatch(createError("Something went wrong"));
				}
			})
			.catch((error) => {
				dispatch(createError(error));
			});
	} catch (e) {
		dispatch(createError(e));
	}
};

export const DeleteCustomerUser = (id) => async (dispatch) => {
	try {
		await axios
			.delete(`${process.env.REACT_APP_API_HOST}/V1/admin/customer/${id}`, {
				headers: headers(),
			})
			.then((response) => {
				console.log("get users", response.data);
				if (!response.data.success) {
					return dispatch(createError("Something went wrong"));
				}
				toast.success(response.data.message);
			})
			.catch((error) => {
				dispatch(createError(error));
			});
	} catch (e) {
		dispatch(createError(e));
	}
};

export const createError = (error) => {
	console.log("error", error);
	if (error && error.response && error.response.status === 401) {
		clearSession();
	}

	return function (dispatch) {
		setTimeout(function () {
			dispatch(clearError());
		}, 2000);
		dispatch({
			type: actionTypes.GET_RESPONSE_ERROR,
			payload: error,
		});
	};
};

export const clearError = () => {
	console.log("clear error");
	return function (dispatch) {
		dispatch({
			type: actionTypes.CLEAR_ERROR,
		});
	};
};

export const checkAuth = async () => {
	try {
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_HOST}/V1/admin/checkAuth`,
			{ headers: headers() }
		);
		return data.success;
	} catch (e) {
		return false;
	}
};

export const getLoggedUser = async () => {
	try {
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_HOST}/V1/admin/getUser?type=admin`,
			{ headers: headers() }
		);
		return data;
	} catch (e) {
		return {};
	}
};
export const deleteUser = async (email) => {
	try {
		const { data } = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/admin/deleteCustomer`,
			{ email: email },
			{ headers: headers() }
		);
		return data;
	} catch (e) {
		return {};
	}
};

export const deleteSoftData = async (email) => {
	try {
		const { data } = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/admin/soft/deleteCustomer`,
			{ email: email },
			{ headers: headers() }
		);
		return data;
	} catch (e) {
		return {};
	}
};
export const generatePAP = async (value) => {
	try {
		let url = `${process.env.REACT_APP_API_HOST}/V1/admin/generatePAPdownload?month=${value}`;
		window.open(url, "_self");
	} catch (e) {
		return {};
	}
};
export const updateApplicationStatus = async (userId, value) => {
	try {
		let url = `${process.env.REACT_APP_API_HOST}/V1/admin/updateApplicationStatus`;
		const response = await axios({
			method: "POST",
			url: url,
			data: { userId: userId, value: value },
			headers: headers(),
		});
		return response;
	} catch (e) {
		return {};
	}
};

export const generateManualPAP = async (type, date,  loanIds="") => {
	try {
		let url = `${process.env.REACT_APP_API_HOST}/V1/admin/generatePAP`;
		const response = await axios({
			method: "POST",
			url: url,
			data: { type: type, dueDate: date , loanIds:loanIds },
			headers: headers(),
		});
		return response;
	} catch (e) {
		return {};
	}
};
export const getPAPFiles = async (text) => {
	try {
		let url = `${process.env.REACT_APP_API_HOST}/V1/admin/getPAPFiles`;
		const response = await axios({
			method: "POST",
			url: url,
			data: { text: text },
			headers: headers(),
		});
		console.log(response.data);
		return response.data;
	} catch (e) {
		console.log(e.message);
	}
};
export const runPaymentJob = async (data) => {
	try {
		let url = `${process.env.REACT_APP_API_HOST}/V1/admin/runPaymentJob`;
		const response = await axios({
			method: "POST",
			url: url,
			data,
			headers: headers(),
		});
		console.log(response.data);
		return response.data;
	} catch (e) {
		console.log(e.message);
	}
};

export const RunPaymentWorkFlowForSingleApplication = async (data) => {
	try {
		let url = `${process.env.REACT_APP_API_HOST}/V1/admin/runPaymentWorkFlowForSingleApplication`;
		const response = await axios({
			method: "POST",
			url: url,
			data,
			headers: headers(),
		});
		console.log(response.data);
		return response.data;
	} catch (e) {
		console.log(e.message);
	}
};

const clearSession = () => {
	localStorage.clear();
	window.location.href = "/login";
};
