import { makeStyles } from "@material-ui/core/styles";

export const OperationalStyle = makeStyles({
	HeadingStyle: {
		fontWeight: "700",
		margin: "25px 0px",
	},
	HeadingBorderStyle: {
		borderBottom: "1px solid #DCDCDC",
		paddingBottom: "15px",
		fontWeight: "700",
		color: "black",
	},
	ButtonOutLineStyle: {
		outline: "none",
	},
	highlightComp: {
		background: "black",
	},
	operationalExportButtonStyle: {
		display: "inline block",
		padding: "0.3em 1.2em",
		margin: "0 0.3em 0.3em 0",
		fontWeight: "300",
		background: "inherit",
		border: "none",
		color: "black",
		outline: "none",
		width: "max-content",
	},
	fromStyle: {
		color: "#000000DE",
		marginLeft: "10px",
		outline: "none",
	},
});
export const ReportFolioStyle = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
		marginBottom: "15px",
	},
	parentTextStyle: {
		padding: "10px 25px",
		verticalAlign: "center",
		margin: "5px 5px",
		"&:hover": {
			background: "#9e9e9e",
			verticalAlign: "center",
			cursor: "pointer",
		},
		PaperProps: {
			maxHeight: 100,
		},
	},

	exportButtonStyle: {
		display: "inline block",
		padding: "0.3em 1.2em",
		margin: "0 0.3em 0.3em 0",
		fontWeight: "300",
		background: "inherit",
		border: "none",
		color: "black",
		outline: "none",
	},
	imageStyle: {
		height: "20px",
		width: "20px",
		marginTop: "6px",
		marginLeft: "10px",
	},
	fontType: {
		color: "#000000DE",
		marginLeft: "10px",
		outline: "none",
	},
	customButtonHover: {
		background: "#2979ff",
		"&:hover": {
			background: "#1c54b2",
		},
	},
}));
export const RisksStyle = makeStyles({});
