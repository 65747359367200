import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPassword } from "../../actions/users";
import logoIcon from "../../assets/icons/QuadFi-Logo.svg";
import { PASSWORD } from "../../utils/const";
import { checkFieldErrors } from "../../utils/fileCheck";

const toastOptions = {
	autoClose: 5000,
	delay: 0,
};

const ResetPassword = (props) => {
	console.log("Password Reset props: ", props);

	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const history = useHistory();

	const search = props.location.search;
	let token;
	if (search.length) {
		token = search.split("=");
		console.log("token: ", token[token.length - 1]);
	}

	const checkErrors = () => {
		const { password, confirmPassword } = formData;
		let errPassword = checkFieldErrors(PASSWORD, password);
		if (errPassword) {
			errors.password = errPassword;
			setErrors({ ...errors, password: errPassword });
		}
		if (errPassword === null) {
			delete errors.password;
			setErrors({ ...errors, password: "" });
		}

		let errConfirmPassword = checkFieldErrors(PASSWORD, confirmPassword);
		if (errConfirmPassword) {
			errors.confirmPassword = errConfirmPassword;
			setErrors({ ...errors, confirmPassword: errPassword });
		}
		if (errConfirmPassword === null) {
			delete errors.confirmPassword;
			setErrors({ ...errors, confirmPassword: "" });
		}
		if (password && confirmPassword && password !== confirmPassword) {
			errors.password = "Password is not same";
			errors.confirmPassword = "Confirm Password is not same";
			setErrors({ ...errors, password: "Password is not same" });
			setErrors({ ...errors, confirmPassword: "Confirm Password is not same" });
		}
	};

	async function handleResetPassword() {
		checkErrors();
		if (Object.keys(errors).length === 0 && token) {
			formData["type"] = "admin";
			formData["token"] = token[token.length - 1];
			const { success, message } = await resetPassword(formData);
			if (success) {
				toast.success(message, toastOptions);
				history.push("/login");
			} else {
				toast.error(message, toastOptions);
			}
		}
	}

	// Render
	return (
		<section>
			<div className="base-container">
				<div className="header">
					<img src={logoIcon} alt="QuadFi Logo" />
				</div>
				<div className="content">
					<div className="form">
						<div className="form-group">
							<input
								type="password"
								name="password"
								placeholder="New Password"
								className="user-input"
								value={formData.password}
								onChange={(e) =>
									setFormData({
										...formData,
										password: e.target.value,
									})
								}
							/>
							<p class="red">{errors.password}</p>
						</div>
						<div className="form-group">
							<input
								type="password"
								name="password"
								placeholder="Confirm Password"
								className="user-input"
								value={formData.confirmPassword}
								onChange={(e) =>
									setFormData({
										...formData,
										confirmPassword: e.target.value,
									})
								}
							/>
							<p class="red">{errors.confirmPassword}</p>
						</div>
					</div>
				</div>

				<div className="footer">
					<button
						type="button"
						className="btn btn-link"
						onClick={() => handleResetPassword()}>
						Reset password
					</button>
				</div>
			</div>
		</section>
	);
};

export default ResetPassword;
