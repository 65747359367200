const { EMAIL, PASSWORD } = require("./const");
const allowedExts = process.env.ALLOWED_FILE_EXTS?.split(",") || [
	"image/jpeg",
	"image/jpg",
	"image/png",
	"application/pdf",
];

const maxAllowedFileSize = (fileType) => {
	if (fileType.includes("image")) {
		return parseInt(process.env.MAX_IMAGE_SIZE || "5", 10) * 1024 * 1024; //Default is 5 MB
	} else if (fileType.includes("pdf")) {
		return parseInt(process.env.MAX_PDF_SIZE || "5", 10) * 1024 * 1024;
	}
	return 0;
};

exports.checkFileBeforeUpload = (file) => {
	if (
		!allowedExts.includes(file.type) ||
		file.size > maxAllowedFileSize(file.type)
	) {
		return { success: false, message: "File Upload Failed" };
	}
	return { success: true, message: "File can be uploaded" };
};

const isEmail = (email) => {
	if (/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(email)) {
		return true;
	}
	return false;
};

exports.checkFieldErrors = (type, value) => {
	if (type === EMAIL) {
		if (!value || value === "") {
			return "Email is required";
		} else if (!isEmail(value)) {
			return "Enter a valid email.";
		} else {
			return null;
		}
	}

	if (type === PASSWORD) {
		if (!value || value === "") {
			return "Password is required";
		} else if (value.length < 8) {
			return "Password should be of minimum 8 characters.";
		} else {
			return null;
		}
	}
};
