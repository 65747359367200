import {
	Button,
	Card,
	Collapse,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@material-ui/core";
import { ArrowRight } from "@material-ui/icons";
import { useState } from "react";
import ReactJson from "react-json-view";
import XMLViewer from "react-xml-viewer";
import { getFirestoreData } from "../../../services/admin/firestoreDecrypt";
import { getModelFiles } from "../../../services/admin/modelDecrypt";
// createTheme()
// const usestyle = makeStyles()

export default function FirestoreDecrypt(props) {
	const [jsonObject, setJsonObject] = useState({});
	const [modelObject, setmodelObject] = useState([]);

	const [appId, setAppId] = useState("");
	const [expanded, setExpanded] = useState(false);
	const [expandAttribute, setExpandAttribute] = useState(0);
	const [hovercolor, setHovercolor] = useState(false);

	const handleExpandClick = (i) => {
		setExpandAttribute(i);
		setExpanded(!expanded);
	};
	const onMouseHover = () => {
		setHovercolor(!hovercolor);
	};
	const fetchFirestoreData = async () => {
		const data = await getFirestoreData(appId);
		setJsonObject(data);
		const modeldata = await getModelFiles(data.appId);
		setmodelObject(modeldata);
	};
	return (
		<>
			<div>
				<h3>Firestore Data </h3>
			</div>
			<Grid
				container
				md={8}
				lg={10}
				spacing={2}
				alignItems="flex-end"
				justifyContent="space-between"
				style={{ margin: "1%", width: "auto" }}>
				<Grid
					item
					container
					// xs={7} sm={4} md={4} lg={3} xl={3}
					justifyContent="space-between"
					alignItems="center"
					style={{ width: "50%" }}>
					<Grid item>
						<Typography variant="body1"> App Id </Typography>
					</Grid>
					<Grid item style={{ width: "auto" }}>
						<TextField
							id="AppId"
							value={appId}
							onChange={(event) => setAppId(event.target.value)}
							style={{ width: "100%" }}
							variant="outlined"
							size="small"
						/>
					</Grid>
				</Grid>
				<Grid item style={{ alignContent: "flex-end", width: "40%" }}>
					<Button
						className=".MuiIconButton-root"
						onMouseEnter={onMouseHover}
						onMouseLeave={onMouseHover}
						style={{
							width: "40%",
							fontWeight: 500,
							color: hovercolor ? "white" : "inherit",
							backgroundColor: hovercolor ? "#3c4b64" : "inherit",
						}}
						onClick={fetchFirestoreData}>
						Fetch
					</Button>
				</Grid>
			</Grid>
			{Object.keys(jsonObject).length > 0 ||
			console.log("jsonObject.length ", Object.keys(jsonObject).length) ? (
				<Card
					elevation={1}
					style={{
						backgroundColor: "rgba(0, 0, 21, 0)",
						margin: "5% 1% 1% 1%",
					}}>
					<Grid
						container
						alignItems="flex-start"
						justifyContent="flex-start"
						style={{ margin: "1%", padding: "1% 1%" }}>
						<Grid item>
							<Typography variant="body1" style={{ marginRight: "15px" }}>
								Firestore Object{" "}
							</Typography>
						</Grid>
						<Grid
							item
							justifyContent="flex-end"
							style={{
								overflow: "auto",
								maxHeight: "20em",
							}}>
							<ReactJson collapsed={true} src={jsonObject} />
						</Grid>
					</Grid>
				</Card>
			) : (
				<></>
			)}
			{modelObject.length &&
				modelObject.map((data, i) => (
					<>
						{typeof data?.data === "object" ? (
							<Card
								elevation={1}
								style={{
									backgroundColor: "rgba(0, 0, 21, 0)",
									margin: "1% 1%",
								}}>
								<Grid
									container
									alignItems="flex-start"
									alignContent="center"
									justifyContent="flex-start"
									style={{ margin: "1%", padding: "1% 1%" }}>
									<Grid item>
										{
											{
												amount_calculate_input: (
													<Typography
														variant="body1"
														style={{ marginRight: "15px" }}>
														Amount Calculate Input
													</Typography>
												),
												quadfi_input: (
													<Typography
														variant="body1"
														style={{ marginRight: "15px" }}>
														Quadfi Input
													</Typography>
												),
												cashflow_input: (
													<Typography
														variant="body1"
														style={{ marginRight: "15px" }}>
														Cashflow Input
													</Typography>
												),
											}[data.fileName]
										}
									</Grid>
									<Grid item>
										<ReactJson
											style={{ alignItems: "center" }}
											collapsed={true}
											src={data.data ? data.data : {}}
										/>
									</Grid>
								</Grid>
							</Card>
						) : (
							<Card
								key={i}
								elevation={1}
								style={{
									backgroundColor: "rgba(0, 0, 21, 0)",
									margin: "1% 1%",
								}}>
								<Grid
									container
									alignItems="center"
									justifyContent="space-between"
									style={{
										margin: "1%  0%",
										padding: "1% 1%",
									}}>
									<Grid
										container
										alignItems="flex-start"
										justifyContent="flex-start">
										<Typography variant="body1" style={{ marginRight: ".5%" }}>
											{data.fileName.includes("softCheck")
												? "Equifax Input (softCheck)"
												: data.fileName.includes("hardCheck")
												? "Equifax Input (hardCheck)"
												: data.fileName}{" "}
										</Typography>
										<IconButton
											disableFocusRipple={true}
											disableRipple={true}
											size="small"
											onClick={() => handleExpandClick(i)}
											aria-expanded={expanded}
											aria-label="show more">
											<ArrowRight />
										</IconButton>
									</Grid>
									<Grid
										item
										justifyContent="flex-end"
										style={{
											overflow: "auto",
											maxHeight: "20em",
										}}>
										{expanded && expandAttribute === i ? (
											<Collapse in={expanded} timeout={0} unmountOnExit>
												<XMLViewer
													xml={data.data ? data.data : {}}
													collapsible={true}
												/>
											</Collapse>
										) : (
											<></>
										)}
									</Grid>
								</Grid>
							</Card>
						)}
					</>
				))}
		</>
	);
}
