import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import { Table } from "react-bootstrap";

export default function AnalyticsTable(props) {
	return (
		<div style={{ margin: "5% 0%" }}>
			<TableContainer>
				<Table id="AnalyticTable">
					<TableHead>
						<TableRow style={{ background: "#cfd8dc" }}>
							<TableCell>
								<b>Date</b>
							</TableCell>
							<TableCell>
								<b>Page</b>
							</TableCell>
							<TableCell>
								<b>DoNotTrack</b>
							</TableCell>
							<TableCell>
								<b>KeyboardClick</b>
							</TableCell>
							<TableCell>
								<b>MouseClick</b>
							</TableCell>
							<TableCell>
								<b>PreviousPage</b>
							</TableCell>
							<TableCell>
								<b>DropOff</b>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>05/10/2021</TableCell>
							<TableCell>loanAmount</TableCell>
							<TableCell>OFF</TableCell>
							<TableCell>0</TableCell>
							<TableCell>0</TableCell>
							<TableCell>NO</TableCell>
							<TableCell>NO</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}
