import { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import { Typography } from "@material-ui/core";
import excel from "../../../../assets/icons/Excel2.png";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

import { ReportFolioStyle } from "../Style/Style";
//----------------------------------End of import--------------------------------------------------//

export default function PortfolioAppBar(props) {
	const classes = ReportFolioStyle();
	const [excelExport, setExcelExport] = useState([]);
	const handleChange = (e) => {
		props.setTerm(e.target.value);
	};
	const fromDateChange = (date) => {
		props.setFromDate(date);
	};

	const toDateChange = (date) => {
		props.setToDate(date);
	};

	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	const exportToCSV = (apiData, fileName) => {
		var excelData = [];
		props.reportData &&
			props.reportData.forEach((item) => {
				let obj = {};
				obj.Date = moment?.(item.created_at).format("YYYY-MM-DD");
				obj.Active_loans = item.active_loans;
				obj.Portfolio = item.portfolio;
				excelData.push(obj);
			});
		// console.log(excelData);
		setExcelExport(excelData);
		const ws = XLSX.utils.json_to_sheet(excelData);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName + fileExtension);
	};

	return (
		<div>
			<AppBar
				position="static"
				style={{
					background: "#DADFE4",
					margin: "10px 0px",
					fontFamily: "sans-serif",
					width: "fit-content",
					minWidth: "100%",
				}}>
				<Toolbar>
					<FormControl>
						<NativeSelect
							style={{
								marginRight: "15px",
								paddingRight: "18px",
								width: "max-content",
							}}
							inputProps={{ "aria-label": "Without label" }}
							displayEmpty>
							<option value={""}> - All Reports -</option>
						</NativeSelect>
					</FormControl>

					<FormControl className={classes.formControl}>
						<NativeSelect
							value={props.term}
							onChange={handleChange}
							displayEmpty
							inputProps={{ "aria-label": "Without label" }}
							// style={{width:"auto"}}
						>
							<option value={"Daily"}>Daily</option>
							<option value={"Weekly"}>Weekly</option>
							<option value={"Monthly"}>Monthly</option>
							<option value={"Quaterly"}>Quaterly</option>
							<option value={"Annually"}>Annually</option>
						</NativeSelect>
					</FormControl>
					<Button
						onClick={() => {
							props.setChartOnly(true);
							props.setTableOnly(false);
						}}
						style={{ outline: "none" }}>
						CHART
					</Button>
					<Button
						onClick={() => {
							props.setTableOnly(true);
							props.setChartOnly(false);
						}}
						style={{ outline: "none" }}>
						TABLE
					</Button>
					<Button
						onClick={() => {
							props.setTableOnly(true);
							props.setChartOnly(true);
						}}
						style={{ outline: "none" }}>
						BOTH
					</Button>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Typography className={classes.fontType}>From</Typography>
						<KeyboardDatePicker
							autoOk={true}
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							id="date-picker-inline"
							value={props.fromDate}
							onChange={fromDateChange}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							style={{
								margin: "0px 10px 0px 10px",
								outline: "none",
								width: "max-content",
							}}
						/>
						<Typography className={classes.fontType}>To</Typography>
						<KeyboardDatePicker
							autoOk={true}
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							id="date-picker-inline"
							value={props.toDate}
							onChange={toDateChange}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							style={{ margin: "0px 10px 0px 10px", outline: "none" }}
						/>
					</MuiPickersUtilsProvider>
					<div style={{ display: "flex" }}>
						<img
							style={{
								height: "20px",
								width: "20px",
								marginTop: "6px",
								marginLeft: "10px",
							}}
							src={excel}
							alt=''
						/>

						<button
							className={classes.exportButtonStyle}
							onClick={(e) => exportToCSV(excelExport, "Portfolio")}>
							EXPORT
						</button>
					</div>
				</Toolbar>
			</AppBar>
		</div>
	);
}
