
export const initializeUsers = () => async (dispatch) => {
	// const userData = await getAllUsers()
	// dispatch({
	//     type: 'INIT_DATA',
	//     payload: await userData,
	// })
};

export const updateUser = (user) => async (dispatch) => {
	// const updateUser =
	dispatch({
		type: "UPDATE_USER_LIST",
		payload: user,
	});
};
