import { ReportFolioStyle } from "../Style/Style";
import { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from "@material-ui/core";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import RiskData from "./RisksData";
import { getRisksData } from "../../../../services/admin/reports";
import { useEffect } from "react";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import excel from "../../../../assets/icons/Excel2.png";
//------------------------------------------End of Import ---------------------------------------------------//

export default function Risks() {
	const classes = ReportFolioStyle();
	let firstDateOfMonth = new Date().setDate(1);
	// eslint-disable-next-line 
	const [fromDate, setFromData] = useState(firstDateOfMonth);
	// eslint-disable-next-line 
	const [toDate, setToDate] = useState(new Date());
	const [riskData, setRiskData] = useState([]);
	const [excelExport, setExcelExport] = useState([]);

	useEffect(() => {
		let data = {
			fromDate: fromDate,
			toDate: toDate,
		};

		async function fetchRiskData() {
			const getRiskDataResult = await getRisksData(data);
			if (getRiskDataResult.success) {
				setRiskData(getRiskDataResult.reports[0]);
			}
			// console.log(getRiskDataResult.reports[0]);
		}
		fetchRiskData();
	},[toDate , fromDate]);

	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	const exportToCSV = (apiData, fileName) => {
		var excelData = [];
		// riskData &&
		// riskData.forEach((item) => {
		let obj = {};
		obj.Early = riskData.early;
		obj.OnTime = riskData.onTime;
		obj.Late = riskData.late;
		obj.Early_Percentage = riskData.early * 100;
		obj.OnTime_Percentage = riskData.onTime * 100;
		obj.Late_Percentage = riskData.late * 100;

		excelData.push(obj);
		// });
		console.log(excelData);
		setExcelExport(excelData);
		const ws = XLSX.utils.json_to_sheet(excelData);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName + fileExtension);
	};

	return (
		<div className="sidebar-content-container" style={{ width: "88%" }}>
			<AppBar
				position="static"
				style={{
					background: "#DADFE4",
					margin: "10px 0px",
					fontFamily: "sans-serif",
					width: "fit-content",
					minWidth: "100%",
				}}>
				<Toolbar>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Typography
							style={{
								color: "#000000DE",
								marginLeft: "10px",
								outline: "none",
							}}>
							From
						</Typography>

						<KeyboardDatePicker
							autoOk={true}
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							id="date-picker-inline"
							// value={fromDate}
							// onChange={handleDateChange}
							onChange={(e)=>setFromData(e)}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							style={{
								margin: "0px 10px 0px 10px",
								outline: "none",
								width: "max-content",
							}}
						/>
						<Typography style={{ color: "#000000DE" }}>To</Typography>
						<KeyboardDatePicker
							autoOk={true}
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							id="date-picker-inline"
							// value={toDate}
							// onChange={toDateChange}
							onChange={(e)=>setToDate(e)}

							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							style={{
								margin: "0px 10px 0px 10px",
								outline: "none",
								width: "max-content",
							}}
						/>
					</MuiPickersUtilsProvider>
					<div style={{ display: "flex" }}>
						<img
							style={{
								height: "20px",
								width: "20px",
								marginTop: "6px",
								marginLeft: "10px",
							}}
							src={excel} alt=''
						/>
						<button
							className={classes.exportButtonStyle}
							onClick={(e) => exportToCSV(excelExport, "Risk")}>
							EXPORT
						</button>
					</div>
				</Toolbar>
			</AppBar>

			<RiskData
				riskData={riskData}
				setRiskData={setRiskData}
				name={"RiskTable"}
			/>
		</div>
	);
}
