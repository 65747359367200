import { makeStyles } from "@material-ui/core/styles";

export const pageStyles = makeStyles((theme) => ({
	topbar: {
		backgroundColor: "#4d4d4e",
	},
	listItemText: {
		fontFamily: "Montserrat",
	},
	pad: {
		marginTop: "85px",
	},
}));
