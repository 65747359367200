import { OperationalStyle } from "../Style/Style";
import { Table, TableCell, TableRow, TableHead } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { Typography } from "@material-ui/core";

//------------------------------------------End of Import ---------------------------------------------------//

export default function OperationalData(props) {
	const style = OperationalStyle();

	return (
		<div className="admin-card custom-form-table">
			<Typography
				className={style.HeadingBorderStyle}
				style={{ margin: "10px 0px" }}>
				Total Loan Volume
			</Typography>

			<Table>
				<TableHead>
					<TableRow>
						<TableCell style={{ width: "25%" }}>
							<Typography>Approved:</Typography>
							<Typography>
								<NumberFormat
									value={props.operationaldata.approvedAmount}
									displayType={"text"}
									thousandSeparator={true}
									prefix={"$"}
									renderText={(value) => <div>{value}</div>}
								/>
							</Typography>
						</TableCell>
						<TableCell style={{ width: "25%" }}>
							<Typography>Rejected:</Typography>
							<Typography>
								<NumberFormat
									value={props.operationaldata.rejectedAmount}
									displayType={"text"}
									thousandSeparator={true}
									prefix={"$"}
									renderText={(value) => <div> {value}</div>}
								/>
							</Typography>
						</TableCell>

						<TableCell>
							<Typography>Progress:</Typography>
							<Typography>
								<NumberFormat
									value={props.operationaldata.progressAmount}
									displayType={"text"}
									thousandSeparator={true}
									prefix={"$"}
									renderText={(value) => <div> {value}</div>}
								/>
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
			</Table>

			<Typography
				className={style.HeadingBorderStyle}
				style={{ display: "flex", marginTop: "10px" }}>
				Total New Loan Volume
			</Typography>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell style={{ width: "24%" }}>
							<Typography>Total:</Typography>
							<Typography>{props.operationaldataNew.totalAmountNew}</Typography>
						</TableCell>
						<TableCell style={{ width: "24%" }}>
							<Typography>Approved:</Typography>
							<Typography>
								{props.operationaldataNew.approvedAmountNew}
							</Typography>
						</TableCell>
						<TableCell style={{ width: "23%" }}>
							<Typography>Rejected:</Typography>
							<Typography>
								{props.operationaldataNew.rejectedAmountNew}
							</Typography>
						</TableCell>

						<TableCell style={{ width: "25%" }}>
							<Typography>Progress:</Typography>
							<Typography>
								{props.operationaldataNew.progressAmountNew}
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
			</Table>

			<Typography
				className={style.HeadingBorderStyle}
				style={{ marginTop: "10px" }}>
				Approval Stats %
			</Typography>
			<Table>
				<TableHead>
					<TableRow style={{ display: "flex" }}>
						<TableCell style={{ width: "25%" }}>
							<Typography>Approved %:</Typography>
							<Typography>{`${(
								(props.operationaldata.totalApproved /
									props.operationaldata.total) *
								100
							).toFixed(2)}`}</Typography>
						</TableCell>
						<TableCell style={{ width: "25%" }}>
							<Typography>Rejected %:</Typography>
							<Typography>{`${(
								(props.operationaldata.totalrejected /
									props.operationaldata.total) *
								100
							).toFixed(2)}`}</Typography>
						</TableCell>
						<TableCell style={{ width: "33.33%" }}>
							<Typography>Progress %:</Typography>
							<Typography>{`${(
								(props.operationaldata.totalprogress /
									props.operationaldata.total) *
								100
							).toFixed(2)}`}</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
			</Table>

			<Typography
				className={style.HeadingBorderStyle}
				style={{ margin: "10px 0px" }}>
				Business Stats
			</Typography>

			<Table>
				<TableHead>
					<TableRow style={{ display: "flex" }}>
						<TableCell style={{ width: "25%" }}>
							<Typography>Total:</Typography>
							<Typography>{props.operationaldata.total}</Typography>
						</TableCell>

						<TableCell style={{ width: "25%" }}>
							<Typography>Approved:</Typography>
							<Typography>{props.operationaldata.totalApproved}</Typography>
						</TableCell>

						<TableCell style={{ width: "24.5%" }}>
							<Typography>Rejected:</Typography>
							<Typography>{props.operationaldata.totalrejected}</Typography>
						</TableCell>
						<TableCell style={{ width: "18%" }}>
							<Typography>Progress:</Typography>
							<Typography>{props.operationaldata.totalprogress}</Typography>
						</TableCell>
						<TableCell>
							<Typography>Closed:</Typography>
							<Typography>{props.operationaldata.totalcompleted}</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
			</Table>
			<Typography
				className={style.HeadingBorderStyle}
				style={{ margin: "10px 0px" }}>
				New Bussiness stats
			</Typography>
			<Table>
				<TableHead>
					<TableRow style={{ display: "flex" }}>
						<TableCell style={{ width: "25%", minHeight: "20px" }}>
							<Typography>Total:</Typography>
							<Typography>
								{props.operationaldataNew.totalcompletedNew}
							</Typography>
						</TableCell>
						<TableCell style={{ width: "25%" }}>
							<Typography>Approved:</Typography>
							<Typography>
								{props.operationaldataNew.totalApprovedNew}
							</Typography>
						</TableCell>
						<TableCell style={{ width: "24.5%" }}>
							<Typography>Rejected:</Typography>
							<Typography>
								{props.operationaldataNew.totalrejectedNew}
							</Typography>
						</TableCell>
						<TableCell style={{ width: "25%" }}>
							<Typography>Progress:</Typography>
							<Typography>
								{props.operationaldataNew.totalprogressNew}
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
			</Table>
		</div>
	);
}
