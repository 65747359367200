import CIcon from "@coreui/icons-react";
import React from "react";

const _nav = [
	{
		_tag: "CSidebarNavItem",
		name: "Users",
		to: "/admin/users",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "Roles",
		to: "/admin/roles",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "Products",
		to: "/admin/products",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},

	{
		_tag: "CSidebarNavItem",
		name: "Purpose",
		to: "/admin/purpose",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "Decision Rules",
		to: "/admin/rules",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "Generate PAP",
		to: "/admin/generatepap",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "Settings",
		to: "/admin/settings",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "Messages",
		to: "/admin/messages",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "Email Notification",
		to: "/admin/email-notification",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "Url Builder",
		to: "/admin/urlbuilder",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "Customers",
		to: "/admin/customers",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "Simulation",
		to: "/admin/simulation",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "Logs",
		to: "/admin/logs",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "AI Model",
		to: "/admin/firestoreDecrypt",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "Upload File",
		to: "/admin/uploadFile",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
	{
		_tag: "CSidebarNavItem",
		name: "Delete Customer",
		to: "/admin/deleteCustomer",
		icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
	},
];

export default _nav;
