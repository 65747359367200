import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import users from "./users";
import roles from "./roles";
import applications from "./applications";
export default combineReducers({
	users: users,
	roles: roles,
	applications: applications,
	router: routerReducer,
});
