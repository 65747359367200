import logoIcon from "../../assets/icons/QuadFi-Logo.svg";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { forgetPassword } from "../../actions/users";
import { EMAIL } from "../../utils/const";
import { checkFieldErrors } from "../../utils/fileCheck";

const toastOptions = {
	autoClose: 5000,
	delay: 0,
};

const ForgetPassword = (props) => {
	console.log("forget password props: ", props);
	const history = useHistory();
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});

	const checkErrors = () => {
		const { email } = formData;
		let errEmail = checkFieldErrors(EMAIL, email);
		if (errEmail) {
			errors.email = errEmail;
			setErrors({ ...errors, email: errEmail });
		}
		if (errEmail === null) {
			delete errors.email;
			setErrors({ ...errors, email: "" });
		}
	};

	async function handleForgetPassword() {
		await checkErrors();
		if (!errors || Object.keys(errors).length === 0) {
			formData["type"] = "admin";
			const { success, message } = await forgetPassword(formData);
			success
				? toast.success(message, toastOptions)
				: toast.error(message, toastOptions);
		}
	}

	return (
		<section>
			<div className="base-container">
				<div className="header">
					<img src={logoIcon} alt="QuadFi Logo" />
				</div>
				<div className="content">
					<div className="form">
						<div className="form-group">
							<input
								type="email"
								name="email"
								placeholder="Email"
								className="user-input"
								value={formData.email}
								onChange={(e) =>
									setFormData({
										...formData,
										email: e.target.value,
									})
								}
							/>
							<p class="red">{errors.email}</p>
						</div>
					</div>
				</div>

				<div className="footer">
					<button
						type="button"
						className="btn btn-link"
						onClick={() => handleForgetPassword()}>
						Reset password
					</button>
					<div style={{ marginTop: "1rem" }}>
						<p
							style={{ color: "#ffa931", display: "inline", cursor: "pointer" }}
							onClick={() => history.push("/login")}>
							Login
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ForgetPassword;
