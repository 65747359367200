import { ReportFolioStyle } from "../Style/Style";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from "@material-ui/core";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import excel from "../../../../assets/icons/Excel2.png";

import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

//----------------------------------End of import--------------------------------------------------//

export default function AnalyticsAppBar() {
	const classes = ReportFolioStyle();

	return (
		<div>
			<AppBar
				position="static"
				style={{
					background: "#DADFE4",
					margin: "10px 0px",
					fontFamily: "sans-serif",
					width: "fit-content",
					minWidth: "100%",
				}}>
				<Toolbar>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<FormControl>
							<NativeSelect
								style={{
									marginRight: "15px",
									paddingRight: "18px",
									width: "max-content",
								}}
								inputProps={{ "aria-label": "Without label" }}
								displayEmpty>
								<option value={""}> - All Reports -</option>
							</NativeSelect>
						</FormControl>

						<Typography
							style={{
								color: "#000000DE",
								marginLeft: "10px",
								outline: "none",
							}}>
							From
						</Typography>

						<KeyboardDatePicker
							autoOk={true}
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							id="date-picker-inline"
							// value={fromDate}
							// onChange={handleDateChange}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							style={{
								margin: "0px 10px 0px 10px",
								outline: "none",
								// width: "max-content",
								width: "170px",
							}}
						/>
						<Typography style={{ color: "#000000DE" }}>To</Typography>
						<KeyboardDatePicker
							autoOk={true}
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							id="date-picker-inline"
							// value={toDate}
							// onChange={toDateChange}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							style={{
								margin: "0px 10px 0px 10px",
								outline: "none",
								// width: "max-content",
								width: "170px",
							}}
						/>
					</MuiPickersUtilsProvider>
					<div style={{ display: "flex" }}>
						<img
							style={{
								height: "20px",
								width: "20px",
								marginTop: "6px",
								marginLeft: "10px",
							}}
							src={excel}
							alt=''
						/>
						<button
							className={classes.exportButtonStyle}
							// onClick={(e) => exportToCSV(excelExport, "Analytics")}
						>
							EXPORT
						</button>
					</div>
				</Toolbar>
			</AppBar>
		</div>
	);
}
