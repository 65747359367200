import { Button } from "@material-ui/core";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import {
	getOperationalData,
	getOperationalTableData,
} from "../../../../services/admin/reports";
import OperationalAppBar from "./AppBar";
import OperationalData from "./OperationalData";
import OperationalTable from "./OperationalTable";
//------------------------------------------End of Import ---------------------------------------------------//

export default function Operational() {
	const [loading, setLoading] = useState(false);

	const [term, setTerm] = useState("Daily");
	let firstDateOfMonth = new Date().setDate(1);
	const [fromDate, setFromDate] = useState(firstDateOfMonth);
	
	// eslint-disable-next-line 
	const [toDate, setToDate] = useState(new Date());
	const [operationaldata, setOperationalData] = useState([]);
	const [operationaldataNew, setOperationalDataNew] = useState([]);
	const [operationalTableData, setOperationalTableData] = useState([]);
	const [tableDataOFNumberAndAmount, settableDataOFNumberAndAmount] = useState({
		number: true,
		amount: false,
	});

	useEffect(() => {
		let data = {
			fromDate: fromDateForm(fromDate),
			toDate: fromDateForm(toDate),
		};

		async function fetchOperationalData() {
			setLoading(true);
			let operationalResult = await getOperationalData(data);
			if (operationalResult.success) {
				setLoading(false);

				setOperationalData(operationalResult.reports[0][0]);
				setOperationalDataNew(operationalResult.reports[1][0]);
			}
		}

		fetchOperationalData();
	}, [fromDate, toDate]);

	useEffect(() => {
		let data = {
			term: term,
			fromDate: tableDateForm(fromDate),
			toDate: tableDateForm(toDate),
		};

		async function operationalTableData() {
			setLoading(true);
			let operationalTableResult = await getOperationalTableData(data);
			if (operationalTableResult.success) {
				setOperationalTableData(operationalTableResult.reports);
				setLoading(false);
			}
			console.log(operationalTableResult.reports);
			setLoading(false);
		}

		operationalTableData();
	}, [term, fromDate, toDate]);

	const tableDateForm = (date) => {
		const tableFormattedDate = moment(date).format("YYYY-MM-DD");
		return tableFormattedDate;
	};

	const fromDateForm = (date) => {
		const formattedDate = moment(date).format("YYYY-MM-DD");
		return formattedDate;
	};

	return (
		<div
			className="sidebar-content-container"
			style={{ display: "flex", flexDirection: "column", width: "88%" }}>
			<div>
				<OperationalAppBar
					fromDate={fromDate}
					setFromDate={setFromDate}
					toDate={toDate}
					setToDate={setToDate}
					term={term}
					setTerm={setTerm}
					operationalTableData={operationalTableData}
				/>
			</div>

			<div>
				<OperationalData
					operationaldata={operationaldata}
					setOperationalData={setOperationalData}
					operationaldataNew={operationaldataNew}
					setOperationalDataNew={setOperationalDataNew}
				/>
				<div className="admin-card">
					<div style={{ display: "flex" }}>
						<Button
							style={{
								background: "#DADFE4",
								marginTop: "10px",
							}}
							onClick={() =>
								settableDataOFNumberAndAmount({ number: true, amount: false })
							}>
							NUMBER
						</Button>
						<Button
							style={{
								background: "#DADFE4",
								marginTop: "10px",
								marginLeft: "10px",
							}}
							onClick={() =>
								settableDataOFNumberAndAmount({ number: false, amount: true })
							}>
							AMOUNT
						</Button>
					</div>
					{loading ? (
						<div className="tableLoader">Processing...</div>
					) : (
						<OperationalTable
							name="operational table"
							operationalTableData={operationalTableData}
							tableDataOFNumberAndAmount={tableDataOFNumberAndAmount}
							settableDataOFNumberAndAmount={tableDataOFNumberAndAmount}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
