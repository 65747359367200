import axios from "axios";
import { headers } from "../../reusable/headers";

export const getPortfolioData = async (data) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/reports/portfolioReport`,
			params: data,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const getPortfolioLoansData = async () => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/reports/portfolioLoans`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getPortfolioInterestData = async () => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/reports/portfolioIntrestReport`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getPortfolioScoreData = async () => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/reports/portfolioScoresReport`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getOperationalData = async (data) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/reports/operationalReport`,
			params: data,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const getOperationalTableData = async (data) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/reports/operationalTable`,
			params: data,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const getRisksData = async (data) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/reports/riskReport`,
			params: data,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getAnalysisCustomer = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/analytics/getAnalytics`,
			params: appId,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getDeviceAnalysisData = async (appId) => {
	if (headers()) {
		return axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin//analytics/getConfig`,
			params: appId,
			headers: headers(),
		})
			.then((response) => {
				return response.data;
			})
			.catch((e) => {
				throw new ErrorEvent(e.message);
			});
	}
};
export const getUtmAnalysisData = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/analytics/getUtm`,
			params: appId,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getCustomStatusData = async (data) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/reports/getAllLoanStatus`,
			params: data,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getReportCustom = async (data) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/reports/custom`,
			params: data,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const downloadReportComplete = async (data) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/reports/custom`,
			params: data,
			headers: headers(),
      responseType: "blob",
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const downloadReportCustom = async (data) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/reports/custom/download`,
			params: data,
			headers: headers(),
      responseType: "blob",
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const downloadInvestorsReport = async (from, to) => {
	try {
		let url = `${process.env.REACT_APP_API_HOST}/V1/admin/getInvestorsReport/${from}/${to}`;
		window.open(url, "_self");
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
