import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
	CBadge,
	CButton,
	CCollapse,
	CDropdown,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
	CLink,
	CNavbar,
	CNavbarBrand,
	CNavbarNav,
	CNavLink,
	CToggler,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { LogoutUser } from "../actions/users";
import {
	getGlobalNotification,
	getNotificationCount,
} from "../services/admin/notification";
import { getPermissions } from "../services/admin/permissions";

const CNavbars = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [originationCount, setOriginationCount] = useState(0);
	const [underwritingCount, setUnderwritingCount] = useState(0);
	const [loanManagerCount, setLoanManagerCount] = useState(0);
	const [fundingCount, setFundingCount] = useState(0);
	const [globalNotification, setGlobalNotification] = useState([]);
	const [permissions, setAllPermissions] = useState([]);

	const handleLogout = async () => {
		console.log("logout");
		await props.LogoutUser();
	};

	useEffect(() => {
		async function fetchData() {
			// const user = JSON.parse(sessionStorage.getItem('user'))
			// user.email = "userisblanksonothign"
			const data = await getPermissions();
			if (data.success) {
				setAllPermissions(data.permissions);
			} else {
				toast.error("permissions are not available");
			}

			const global = await getGlobalNotification();
			if (global) {
				console.log("global", global);
				if (global.success === true) {
					global.result = global.result.filter((item) => {
						return item != null;
					});
					await setGlobalNotification(global.result);
				}
			}

			const result = await getNotificationCount();
			console.log("======resultCount======", result);
			if (result) {
				setOriginationCount(result.OriginationCount.count);
				setUnderwritingCount(result.UnderwritingCount.count);
				setLoanManagerCount(result.LoanManagerCount.count);
				setFundingCount(result.FundingCount.count);
			}
		}
		fetchData();
	}, []);

	// permissions  permissions.includes("")
	const originator =
		permissions.includes("Origination") || permissions.includes("Super Admin");
	const underwriter =
		permissions.includes("Underwriting") || permissions.includes("Super Admin");
	const funding =
		permissions.includes("Funding") || permissions.includes("Super Admin");
	const servicing =
		permissions.includes("Servicing") || permissions.includes("Super Admin");
	const banking =
		permissions.includes("Banking") || permissions.includes("Super Admin");
	const reports =
		permissions.includes("Reports") || permissions.includes("Super Admin");
	const admin =
		permissions.includes("Admin") || permissions.includes("Super Admin");
	const collections =
		permissions.includes("Collection") || permissions.includes("Super Admin");
	const customers =
		permissions.includes("Past Customers") ||
		permissions.includes("Super Admin");
	return (
		<>
			<CNavbar expandable="sm" className="navBack fixed-top" color="dark">
				<CToggler inNavbar onClick={() => setIsOpen(!isOpen)} />
				<CNavbarBrand>QuadFi</CNavbarBrand>
				<CCollapse show={isOpen} navbar>
					<CNavbarNav>
						{/* <CNavLink as={Link} className="navSize" to='/customer'>Customer</CNavLink> */}
						{originator && (
							<CNavLink as={Link} className="navSize" to="/origination">
								Origination{" "}
								{originationCount > 0 && (
									<CBadge color="secondary">{originationCount}</CBadge>
								)}
							</CNavLink>
						)}
						{underwriter && (
							<CNavLink as={Link} className="navSize" to="/underwriting">
								Underwriting{" "}
								{underwritingCount > 0 && (
									<CBadge color="secondary">{underwritingCount}</CBadge>
								)}
							</CNavLink>
						)}
						{funding && (
							<CNavLink as={Link} className="navSize" to="/funding">
								Funding{" "}
								{fundingCount > 0 && (
									<CBadge color="secondary">{fundingCount}</CBadge>
								)}
							</CNavLink>
						)}

						{servicing && (
							<CNavLink as={Link} className="navSize" to="/servicing">
								Servicing{" "}
								{loanManagerCount > 0 && (
									<CBadge color="secondary">{loanManagerCount}</CBadge>
								)}
							</CNavLink>
						)}
						{collections && (
							<CNavLink as={Link} className="navSize" to="/collections">
								Collections
							</CNavLink>
						)}
						{banking && (
							<CNavLink as={Link} className="navSize" to="/banking">
								Banking
							</CNavLink>
						)}
						{reports && (
							<CNavLink as={Link} className="navSize" to="/report">
								Reports
							</CNavLink>
						)}
						{/* <CNavLink as={Link} className="navSize" to='/archived'>Archive</CNavLink> */}

						{/* <CNavLink as={Link} className="navSize" to='/filter'>Filter</CNavLink> */}
						{admin && (
							<CNavLink as={Link} className="navSize" to="/admin">
								Admin
							</CNavLink>
						)}
						{customers && (
							<CNavLink as={Link} className="navSize" to="/completed">
								Past Customers
							</CNavLink>
						)}
						{customers && (
							<CNavLink as={Link} className="navSize" to="/archived">
								Archived Customers
							</CNavLink>
						)}
					</CNavbarNav>
				</CCollapse>

				<CDropdown className="c-header-nav-item mx-auto p-1">
					<CDropdownToggle className="c-header-nav-link" caret={false}>
						<CButton>
							<CIcon size={"xl"} content={freeSet.cilBell} />
							<CBadge color="secondary">{globalNotification.length}</CBadge>
						</CButton>
					</CDropdownToggle>
					<CDropdownMenu placement="bottom-end" className="pt-0">
						{globalNotification && globalNotification.length
							? globalNotification.map((item, index) => (
									<CDropdownItem
										key={index}
										header
										tag="div"
										className="text-center"
										color="light">
										{/* <strong>You have {this.props.data.unread_message_count } notifications</strong> */}
										<CLink
											as={Link}
											to={
												item && item.appStatus <= 11
													? "/origination"
													: item && item.appStatus >= 12 && item.appStatus <= 14
													? "/underwriting"
													: item && item.appStatus === 15
													? "/funding"
													: item && item.appStatus >= 16 && item.appStatus <= 20
													? "/loan_manager"
													: "/origination"
											}>
											<strong>{item.message + " " + item.appId}</strong>
										</CLink>
									</CDropdownItem>
							  ))
							: ""}

						{/* <CDropdownItem
              header
              tag="div"
              className="text-center"
              color="light"
            > */}
						{/* <strong>You have {this.props.data.unread_message_count } notifications</strong> */}
						{/* <CLink as={Link} to="/admin/messages">
                <strong>You have {notificationCount} unread messages.</strong>
              </CLink>
            </CDropdownItem> */}
						{
							// this.props.unRead.slice(0, this.props.unRead.length).map(newestMessage => (
							//   <CDropdownItem onClick={() => this.handleMessage(newestMessage)}>
							//     <CIcon name="cil-user-follow"
							//       className="mr-2 text-success"/>
							//       {newestMessage.content.subject}
							//   </CDropdownItem>
							// ))
						}
					</CDropdownMenu>
				</CDropdown>

				<CNavLink className="navSize float-right white" onClick={handleLogout}>
					Logout
				</CNavLink>
			</CNavbar>
		</>
	);
};

const mapStateToProps = (state) => ({
	data: state.users,
});

export default withRouter(connect(mapStateToProps, { LogoutUser })(CNavbars));
