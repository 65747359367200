import axios from "axios";
import { headers } from "../../reusable/headers";

export const getMessages = async () => {
	try {
		const result = await axios.get(
			`${process.env.REACT_APP_API_HOST}/V1/admin/message/list`,
			{ headers: headers() }
		);
		return result.data;
	} catch (e) {
		console.log(e.message);
	}
};

export const getNotificationCount = async () => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/applications/notification/count`,
			headers: headers(),
		});
		console.log("responseDATA", response);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const getGlobalNotification = async () => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/applications/globalNotification/count`,
			headers: headers(),
		});
		console.log("responseDATA", response);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

export const updateNotificationCount = async (appId) => {
	try {
		const response = await axios({
			method: "PUT",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/applications/notification/count/${appId}`,
			headers: headers(),
		});
		console.log("responseDATA", response);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};
