import { Typography } from "@material-ui/core";
import {
	ComposedChart,
	Line,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
} from "recharts";
import { PieChart, Pie, Cell } from "recharts";
//----------------------------------End of import--------------------------------------------------//

export default function Chart(props) {
	const data = [
		{
			name: "Student",
			value:
				(props.sumOfLoans.sumOfStudentLoan /
					(props.sumOfLoans.sumOfPersonalLoan +
						props.sumOfLoans.sumOfStudentLoan +
						props.sumOfLoans.sumOfRefinancingloan)) *
				100,
		},
		{
			name: "Personal",
			value:
				(props.sumOfLoans.sumOfPersonalLoan /
					(props.sumOfLoans.sumOfPersonalLoan +
						props.sumOfLoans.sumOfStudentLoan +
						props.sumOfLoans.sumOfRefinancingloan)) *
				100,
		},
		{
			name: "Refinancing",
			value:
				(props.sumOfLoans.sumOfRefinancingloan /
					(props.sumOfLoans.sumOfPersonalLoan +
						props.sumOfLoans.sumOfStudentLoan +
						props.sumOfLoans.sumOfRefinancingloan)) *
				100,
		},
	];

	const COLORS = ["#5393ff", "#a2cf6e", "#0277bd"];

	const RADIAN = Math.PI / 180;
	const renderCustomizedLabel = ({
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		percent,
		index,
	}) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text
				x={x}
				y={y}
				fill="white"
				textAnchor={x > cx ? "start" : "end"}
				dominantBaseline="central">
				{`${(percent * 100).toFixed(2)}%`}
			</text>
		);
	};

	return (
		<div>
			<div style={{ margin: "auto", display: "table" }}>
				<Typography
					style={{
						fontWeight: "500",
						textAlign: "center",
						marginBottom: "10px",
						marginTop: "10px",
						fontSize: 20,
					}}>
					Pie Chart
				</Typography>
				<PieChart width={450} height={450}>
					<Pie
						data={data}
						cx="50%"
						cy="50%"
						labelLine={false}
						label={renderCustomizedLabel}
						outerRadius={200}
						fill="#8884d8"
						dataKey="value">
						{data.map((entry, index) => (
							<Cell
								key={`cell-${index}`}
								fill={COLORS[index % COLORS.length]}
							/>
						))}
					</Pie>
					<Legend />
				</PieChart>
			</div>

			<div style={{ marginTop: "30px" }}>
				<Typography
					style={{
						fontWeight: "500",
						textAlign: "center",
						marginBottom: "10px",
						marginTop: "70px",
						fontSize: 20,
					}}>
					Active Loans vs Portfolio
				</Typography>

				<ComposedChart
					width={1000}
					height={500}
					data={props.reportData}
					margin={{
						top: 30,
						right: 20,
						bottom: 20,
						left: 20,
					}}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="created_on" />
					<YAxis orientation="left" yAxisId={0} />
					<YAxis orientation="right" yAxisId={1} />
					<Tooltip cursor={false} />
					<Area
						type="monotone"
						dataKey="active_loans"
						stroke="#5393ff"
						fill="#5393ff"
						orientation="right"
						yAxisId={0}
					/>
					<Line
						type="monotone"
						dataKey="portfolio"
						stroke="#a2cf6e"
						yAxisId={1}
					/>
					<Legend layout="vertical" verticalAlign="top" align="right" />
				</ComposedChart>
			</div>
		</div>
	);
}
