import React from "react";
import NavigationRoute from "./route/router";
import { connect } from "react-redux";
import "./scss/style.scss";
import { initializeUsers } from "./redux/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withOneTabEnforcer } from "react-one-tab-enforcer";
import axios from "axios";
import { headers } from "./reusable/headers";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			currentUserRole: "Admin",
		};
	}

	async componentDidMount() {
		await this.props.initializeUsers();
		this.setState({
			users: await this.props.user,
			loading: false,
		});

		document.addEventListener("visibilitychange", () => {
			const visibilityState = document.visibilityState === "hidden" ? 0 : 1;
			axios.post(
				`${process.env.REACT_APP_API_HOST}/V1/admin/updateSessionStatus`,
				{ sessionStatus: visibilityState },
				{ headers: headers() }
			);
		});
	}

	render() {
		// if (this.state.loading) {
		//     return (<p>Loading.....</p>);
		// }
		return (
			<div>
				<NavigationRoute role={this.state.currentUserRole} />
				<ToastContainer autoClose={2000} />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({ user: state.user });

const DifferentWarningComponent = () => (
	<div>
		Sorry! You can only have this application opened in one tab. If trying to
		reset password, please close other quadfi's opened tabs and refresh this
		page.
	</div>
);

export default connect(mapStateToProps, { initializeUsers })(
	withOneTabEnforcer({
		appName: "admin-portal",
		OnlyOneTabComponent: DifferentWarningComponent,
	})(App)
);
