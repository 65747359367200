const { LOAN_ID_DOCUMENT } = require("./utils/const");

const DATE_OPTIONS = {
	FORMAT: "en-CA",
	TIMEZONE: "America/Toronto",
};

exports.formatDate = (utcDate, onlyDate = false) => {
	try {
		if (!utcDate) return "N/A";
		let localDate = new Date(utcDate);
		if (onlyDate) {
			return localDate.toLocaleDateString(DATE_OPTIONS.FORMAT, {
				timeZone: DATE_OPTIONS.TIMEZONE,
			});
		} else {
			return (
				localDate.toLocaleDateString(DATE_OPTIONS.FORMAT, {
					timeZone: DATE_OPTIONS.TIMEZONE,
				}) +
				" " +
				localDate.toLocaleTimeString("en-US", {
					timeZone: DATE_OPTIONS.TIMEZONE,
				})
			);
		}
	} catch (e) {
		console.log("Formatting Error");
		return "";
	}
};

exports._calculateAge = (birthday) => {
	// birthday is a date
	if (!birthday) return "";
	var ageDifMs = Date.now() - birthday.getTime();
	var ageDate = new Date(ageDifMs); // miliseconds from epoch
	return Math.abs(ageDate.getUTCFullYear() - 1970);
};

exports.formatAmount = (amount) => {
	if (!amount) return 0;
	try {
		if (amount) {
			amount = Number(amount).toFixed(2);
			return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		} else {
			return amount;
		}
	} catch (e) {
		throw new Error(e.message);
	}
};

exports.getLoanIdforDocument = (appId) => {
	return (
		"*".repeat(LOAN_ID_DOCUMENT.STAR_LENGTH) +
		appId.slice(appId.length - LOAN_ID_DOCUMENT.SUFFIX_LENGTH).toUpperCase()
	);
};

exports.formatLoanStatus = (status) => {
	return status.replace(/_/g, " ");
};
