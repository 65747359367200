import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useState } from "react";

const PaginationComponent = (props) => {
	console.log(`Pagination props:`, props);

	let pages = [];
	for (let i = 1; i <= props.pagesCount; i++) {
		pages.push(i);
	}

	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	// eslint-disable-next-line
	const [pageSizeOptions, setPageSizeOptions] = useState([5, 10, 25, 50, 100]);

	const setPaginationPage = async (page) => {
		props.handleSelection(page, pageSize);
		setCurrentPage(page);
	};

	const setPrevPage = async () => {
		setCurrentPage(currentPage - 1);
		let page = currentPage - 1;
		props.handleSelection(page, pageSize);
	};

	const setNextPage = async () => {
		setCurrentPage(currentPage + 1);
		let page = currentPage + 1;
		props.handleSelection(page, pageSize);
	};

	const handlePageSizeSelection = (pageSize) => {
		setPageSize(pageSize);
		setCurrentPage(1);
		props.handleSelection(1, pageSize);
	};

	return (
		<>
			<div
				className="server-pagination"
				style={{ paddingLeft: 20, paddingBottom: 15 }}>
				<FormControl className="per-page-wrapper">
					<InputLabel>Per Page</InputLabel>
					<Select
						value={pageSize}
						onChange={(event) => handlePageSizeSelection(event.target.value)}>
						{pageSizeOptions.length &&
							pageSizeOptions.map((item, index) => (
								<MenuItem key={index} value={item}>
									{item}
								</MenuItem>
							))}
					</Select>
				</FormControl>

				{pages.length > 1 && currentPage > 1 ? (
					<span
						onClick={setPrevPage}
						style={{
							display: "inline-block",
							border: "solid 1px lightgray",
							padding: "5px 10px",
							marginRight: 5,
							cursor: "pointer",
						}}>
						Prev
					</span>
				) : null}
				{pages.length > 0
					? pages.map((pageNumber, index) => {
							return (
								<span
									className={pageNumber === currentPage ? "active-page" : ""}
									onClick={() => setPaginationPage(pageNumber)}
									style={{
										display: "inline-block",
										border: "solid 1px lightgray",
										padding: "5px 10px",
										marginRight: 5,
										cursor: "pointer",
									}}>
									{pageNumber}
								</span>
							);
					  })
					: null}
				{pages.length > 1 && currentPage < pages.length ? (
					<span
						onClick={setNextPage}
						style={{
							display: "inline-block",
							border: "solid 1px lightgray",
							padding: "5px 10px",
							marginRight: 5,
							cursor: "pointer",
						}}>
						Next
					</span>
				) : null}
			</div>
		</>
	);
};
export default PaginationComponent;
