import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import {
	downloadInvestorsReport,
	downloadReportComplete,
	downloadReportCustom,
	getReportCustom,
} from "../../../../services/admin/reports";
import {
	formatAmount,
	formatDate,
	getLoanIdforDocument,
} from "../../../../utils";
import CustomFilters from "./customFilters";
import PaginationComponent from "./pagination";
import "./style.scss";

//----------------------------------End of import--------------------------------------------------//

export default function Custom() {
	const [loading, setLoading] = useState(true);
	const [pages, setPages] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	let fromDefaultDate = new Date();
	fromDefaultDate = fromDefaultDate.setDate(1);
	let toDefaultDate = new Date().getTime();
	let [filterValues, setFilterValues] = useState({
		fromDate: moment.unix(fromDefaultDate / 1000).format("YYYY-MM-DD"),
		toDate: moment.unix(toDefaultDate / 1000).format("YYYY-MM-DD"),
		offerMinAmount: 0,
		offerMaxAmonut: 0,
		loanMinAmount: 0,
		loanMaxAmount: 0,
		loanStatus: [],
		products: [],
		page: currentPage,
		pageSize,
		isDownload: 0,
	});
	const [customData, setCustomData] = useState([]);

	const setReportCustom = async (params) => {
		setLoading(true);
		let res = await getReportCustom(params);
		let divident = Math.floor(res.appliactionsCount / params.pageSize);
		let rem = res.appliactionsCount % params.pageSize;
		if (rem > 0) divident = divident + 1;

		console.log(`divident:`, divident);
		setPages(divident);
		setCustomData(res.applications);
		setLoading(false);
	};

	useEffect(() => {
		async function fetchData() {
			await setReportCustom(filterValues);
		}
		fetchData();
	}, [filterValues]);

	const handleFilterSearch = (filterObj) => {
		filterValues = {
			...filterValues,
			...filterObj,
			page: currentPage,
			pageSize,
			isDownload: 0,
		};
		setFilterValues(filterValues);
		console.log(`handleFilterSearch:`, filterValues);
		setReportCustom(filterValues);
	};

	const handleReportCompleteDownload = async (filterObj) => {
		filterValues = {
			...filterValues,
			...filterObj,
			isDownload: 1,
		};
		setFilterValues(filterValues);
		console.log(`handleReportCompleteDownload:`, filterValues);
		let blobData = await downloadReportComplete(filterValues);
		const urls = window.URL.createObjectURL(new Blob([blobData]));
		const link = document.createElement("a");
		link.href = urls;
		link.setAttribute(
			"download",
			`Complete_Report_${moment().format("YYYY-MM-DD")}.xlsx`
		);
		document.body.appendChild(link);
		link.click();
	};

	const handleReportCustomDownload = async (filterObj) => {
		filterValues = {
			...filterValues,
			...filterObj,
			isDownload: 1,
		};
		setFilterValues(filterValues);
		console.log(`handleReportCustomDownload:`, filterValues);
		let blobData = await downloadReportCustom(filterValues);
		const urls = window.URL.createObjectURL(new Blob([blobData]));
		const link = document.createElement("a");
		link.href = urls;
		link.setAttribute(
			"download",
			`Custom_Report_${moment().format("YYYY-MM-DD")}.xlsx`
		);
		document.body.appendChild(link);
		link.click();
	};

	const handleInvestorReportDownload = async (filterObj) => {
		console.log(
			`handleInvestorReportDownload fromDate: ${filterObj.fromDate}, toDate: ${filterObj.toDate}`
		);
		await downloadInvestorsReport(filterObj.fromDate, filterObj.toDate);
	};

	const handlePaginationSelection = (currentPage, pageSize) => {
		console.log(`currentPage, pageSize`, currentPage, pageSize);
		setCurrentPage(currentPage);
		setPageSize(pageSize);
		filterValues = {
			...filterValues,
			page: currentPage,
			pageSize: pageSize,
			isDownload: 0,
		};
		setFilterValues(filterValues);
		console.log(`handlePaginationSelection:`, filterValues);
		setReportCustom(filterValues);
	};

	return (
		<div className="sidebar-content-container custom-report-wrapper">
			<div className="filter-row">
				<CustomFilters
					handleSearch={handleFilterSearch}
					handleCompleteDownload={handleReportCompleteDownload}
					handleCustomDownload={handleReportCustomDownload}
					handleInvestorReportDownload={handleInvestorReportDownload}
				/>
				{loading ? (
					<div className="tableLoader">Loading...</div>
				) : (
					<div className="admin-card">
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow style={{ background: "#cfd8dc" }}>
										<TableCell>
											<b>No:</b>
										</TableCell>
										<TableCell>
											<b>Report Date:</b>
										</TableCell>
										<TableCell>
											<b>Created At:</b>
										</TableCell>
										<TableCell>
											<b>Start Time:</b>
										</TableCell>
										<TableCell>
											<b>Finish Time:</b>
										</TableCell>
										<TableCell>
											<b>Processing Time:</b>
										</TableCell>
										<TableCell>
											<b>Requested Amount:</b>
										</TableCell>
										<TableCell>
											<b>Offered Amount:</b>
										</TableCell>
										<TableCell>
											<b>Email:</b>
										</TableCell>
										<TableCell>
											<b>Date Of Birth:</b>
										</TableCell>
										<TableCell>
											<b>Loan ID:</b>
										</TableCell>
										<TableCell>
											<b>Term:</b>
										</TableCell>
										<TableCell>
											<b>Interest Rate:</b>
										</TableCell>
										<TableCell>
											<b>Loan Amount:</b>
										</TableCell>
										<TableCell>
											<b>Channel:</b>
										</TableCell>
										<TableCell>
											<b>Page:</b>
										</TableCell>
										<TableCell>
											<b>Product:</b>
										</TableCell>
										<TableCell>
											<b>Purpose:</b>
										</TableCell>
										<TableCell>
											<b>Status:</b>
										</TableCell>
										<TableCell>
											<b>Schedule Status</b>
										</TableCell>
										<TableCell>
											<b>Original Principal Balance:</b>
										</TableCell>
										<TableCell>
											<b>Amount Funded</b>
										</TableCell>
										<TableCell>
											<b>Origination Date:</b>
										</TableCell>
										<TableCell>
											<b>Funding Date:</b>
										</TableCell>
										<TableCell>
											<b>Maturity Date:</b>
										</TableCell>
										<TableCell>
											<b>Scheduled Payment Amount:</b>
										</TableCell>
										<TableCell>
											<b>Frequency of Payment:</b>
										</TableCell>
										<TableCell>
											<b>Country:</b>
										</TableCell>
										<TableCell>
											<b>Monthly Payment Amount:</b>
										</TableCell>
										<TableCell>
											<b>Customer Gross Income:</b>
										</TableCell>
										<TableCell>
											<b>Customer Free Cashflow:</b>
										</TableCell>
										<TableCell>
											<b>FICO:</b>
										</TableCell>
										<TableCell>
											<b>Q-Score:</b>
										</TableCell>
										<TableCell>
											<b>Homeowner:</b>
										</TableCell>
										<TableCell>
											<b>Type Of Degree:</b>
										</TableCell>
										<TableCell>
											<b>Age Bucket:</b>
										</TableCell>
										<TableCell>
											<b>TDSR:</b>
										</TableCell>
										<TableCell>
											<b>TDSR ALL:</b>
										</TableCell>
										<TableCell>
											<b>Industry:</b>
										</TableCell>
										<TableCell>
											<b>Currency:</b>
										</TableCell>
										<TableCell>
											<b>Open Banking Cashflow:</b>
										</TableCell>
									</TableRow>
								</TableHead>

								{customData &&
									customData.map((item, index) => (
										<TableBody>
											<TableRow>
												<TableCell>{index + 1}</TableCell>
												<TableCell>{item?.reportDate}</TableCell>
												<TableCell>{item?.createdAt}</TableCell>
												<TableCell>{formatDate(item?.startTime)}</TableCell>
												<TableCell>{formatDate(item?.finishTime)}</TableCell>
												<TableCell>{item?.processingTime}</TableCell>
												<TableCell>
													{formatAmount(item?.requestedAmount)}
												</TableCell>
												<TableCell>
													{formatAmount(item?.offeredAmount)}
												</TableCell>
												<TableCell>{item?.email}</TableCell>
												<TableCell>{item?.dob}</TableCell>
												<TableCell>
													{getLoanIdforDocument(item?.loanId)}
												</TableCell>
												<TableCell>{item?.originalTerm}</TableCell>
												<TableCell>{item?.interestRate} %</TableCell>
												<TableCell>
													<NumberFormat
														value={item?.loanAmount}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$"}
														renderText={(value) => <div>{value}</div>}
													/>
												</TableCell>
												<TableCell>{item?.channel}</TableCell>
												<TableCell>{item?.page}</TableCell>
												<TableCell>{item?.product}</TableCell>
												<TableCell>{item?.purpose}</TableCell>
												<TableCell>{item?.status}</TableCell>
												<TableCell>{item?.scheduleStatus ?? "-"}</TableCell>
												<TableCell>
													<b>{"$" + item?.originalPrincipalBalance}</b>
												</TableCell>
												<TableCell>
													<b>{"$" + formatAmount(item?.amountFunded)}</b>
												</TableCell>
												<TableCell>
													<b>{item?.originationDate}</b>
												</TableCell>
												<TableCell>
													<b>{item?.fundingDate || "N/A"}</b>
												</TableCell>
												<TableCell>
													<b>{item?.maturityDate}</b>
												</TableCell>
												<TableCell>
													<b>{"$" + item?.scheduledPaymentAmount}</b>
												</TableCell>
												<TableCell>
													<b>{item.frequencyOfPayment}</b>
												</TableCell>
												<TableCell>
													<b>{item?.country}</b>
												</TableCell>
												<TableCell>
													<b>
														{"$" + formatAmount(item?.monthlyPaymentAmount)}
													</b>
												</TableCell>
												<TableCell>
													<b>
														{"$" +
															formatAmount(item?.customerGrossAnnualIncome)}
													</b>
												</TableCell>
												<TableCell>
													<b>{item?.customerFreeCashflow}</b>
												</TableCell>
												<TableCell>
													<b>{item?.FICO}</b>
												</TableCell>
												<TableCell>
													<b>{item?.qScore}</b>
												</TableCell>
												<TableCell>
													<b>{item?.homeowner}</b>
												</TableCell>
												<TableCell>
													<b>{item?.typeOfDegree}</b>
												</TableCell>
												<TableCell>
													<b>{item?.ageBucket}</b>
												</TableCell>
												<TableCell>
													<b>{item?.TDSR}</b>
												</TableCell>
												<TableCell>
													<b>{item?.["TDSR ALL"]}</b>
												</TableCell>
												<TableCell>
													<b>{item?.Industry}</b>
												</TableCell>
												<TableCell>
													<b>{item?.currency}</b>
												</TableCell>
												<TableCell>
													<b>{item?.openBankingCashflow}</b>
												</TableCell>
											</TableRow>
										</TableBody>
									))}
							</Table>
						</TableContainer>
					</div>
				)}
			</div>
			{pages > 0 && (
				<PaginationComponent
					pagesCount={pages}
					handleSelection={handlePaginationSelection}
				/>
			)}
		</div>
	);
}
