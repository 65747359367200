import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	CCreateElement,
	CSidebar,
	CSidebarNav,
	CSidebarNavDivider,
	CSidebarNavTitle,
	CSidebarMinimizer,
	CSidebarNavDropdown,
	CSidebarNavItem,
} from "@coreui/react";


// sidebar nav config
import navigation from "./sideNav";

const TheSidebar = () => {
	const dispatch = useDispatch();
	const show = useSelector((state) => state.sidebarShow);

	return (
		<CSidebar
			show={show}
			className="sideBar"
			onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}>
			<CSidebarNav>
				<CCreateElement
					items={navigation}
					components={{
						CSidebarNavDivider,
						CSidebarNavDropdown,
						CSidebarNavItem,
						CSidebarNavTitle,
					}}
				/>
			</CSidebarNav>
			<CSidebarMinimizer className="c-d-md-down-none" />
		</CSidebar>
	);
};

export default React.memo(TheSidebar);
