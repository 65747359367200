import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
	generateToken,
	LoginUser,
	verifyTokenExpiry,
} from "../../actions/users";
import logoIcon from "../../assets/icons/QuadFi-Logo.svg";
import store from "../../store";

const toastOptions = {
	autoClose: 5000,
	delay: 0,
};

const Login = (props) => {
	const history = useHistory();
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [isTokenGenerated, setIsTokenGenerated] = useState(false);

	const isEmail = (email) => {
		if (/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(email)) {
			return true;
		}
		return false;
	};

	const checkErrors = (forEmail, forPassword) => {
		if (forEmail) {
			if (!formData.email || formData.email === "") {
				errors.email = "Email is required";
				setErrors({ ...errors, email: "Email is required" });
			} else if (!isEmail(formData.email)) {
				errors.email = "Enter a valid email.";
				setErrors({ ...errors, email: "Enter a valid email." });
			} else {
				delete errors.email;
				setErrors({ ...errors, email: "" });
			}
		}

		if (forPassword) {
			if (!formData.password || formData.password === "") {
				errors.password = "Password is required";
				setErrors({ ...errors, password: "Password is required" });
			} else if (formData.password.length < 8) {
				errors.password = "Password should be of minimum 8 characters.";
				setErrors({
					...errors,
					password: "Password should be of minimum 8 characters.",
				});
			} else {
				delete errors.password;
				setErrors({ ...errors, password: "" });
			}
		}
	};

	// const checkTokenErrors = () => {
	// 	if (!formData.token || formData.token === "") {
	// 		errors.token = "Token is required";
	// 		setErrors({ ...errors, token: "Token is required" });
	// 	} else {
	// 		delete errors.token;
	// 		setErrors({ ...errors, token: "" });
	// 	}
	// };

	async function handleSubmit() {
		checkErrors(true, true);
		if (!errors || Object.keys(errors).length === 0) {
			const response = await verifyTokenExpiry(formData);
			if (response) {
				const { message, authenticated, sessionToken } = response;
				if (
					authenticated === false &&
					message !== "" &&
					message === "Token expired"
				) {
					const { success, message } = await generateToken(formData);
					if (success) {
						toast.success(
							"Token expired!\nNew token has been sent on your registered email",
							toastOptions
						);
					} else {
						toast.error(message, toastOptions);
					}
					return;
				}
				if (authenticated) {
					sessionStorage.setItem("_st", sessionToken);
					await props.LoginUser(response);
					if (store.getState().users.authenticated)
						window.location.pathname = "/origination";
				}
			} else {
				toast.error("Invalid Parameter", toastOptions);
			}
		}
	}

	async function handleGenerateToken() {
		checkErrors(true, true);
		if (!errors || Object.keys(errors).length === 0) {
			formData["type"] = "admin";
			const { success, message } = await generateToken(formData);
			if (success) {
				toast.success(message, toastOptions);
				setIsTokenGenerated(true);
			} else {
				toast.error(message, toastOptions);
			}
		}
	}

	// Render
	return (
		<section>
			<div className="base-container">
				<div className="header">
					<img src={logoIcon} alt="QuadFi Logo" />
				</div>
				<div className="content">
					<div className="form">
						<div className="form-group">
							<input
								type="email"
								name="email"
								placeholder="Email Address"
								className="user-input"
								value={formData.email}
								onChange={(e) =>
									setFormData({
										...formData,
										email: e.target.value,
									})
								}
							/>
							<p class="red">{errors.email}</p>
						</div>
						<div className="form-group">
							<input
								type="password"
								name="password"
								placeholder="Password"
								className="user-input"
								value={formData.password}
								onChange={(e) =>
									setFormData({
										...formData,
										password: e.target.value,
									})
								}
							/>
							<p class="red">{errors.password}</p>
						</div>
						{isTokenGenerated && (
							<div className="form-group">
								<input
									type="text"
									name="token"
									placeholder="Token"
									className="user-input"
									value={formData.token}
									onChange={(e) =>
										setFormData({
											...formData,
											token: e.target.value,
										})
									}
								/>
								<p className="red">{errors.token}</p>
								<p>Token is sent on your registered email</p>
							</div>
						)}
					</div>
				</div>
				{isTokenGenerated && (
					<div className="cta-button">
						<button
							type="button"
							className="btn custom-button"
							onClick={() => handleSubmit()}>
							Verify Token
						</button>
					</div>
				)}
				{!isTokenGenerated && (
					<div className="cta-button">
						<button
							type="button"
							className="btn custom-button"
							onClick={() => handleGenerateToken()}>
							Sign In
						</button>
					</div>
				)}
				<div className="footer">
					<button
						type="button"
						className="btn"
						onClick={() => history.push("/forgetPassword")}>
						Forgot password ?
					</button>
					{isTokenGenerated && (
						<span>
							<p
								style={{ color: "blue", display: "inline", cursor: "pointer" }}
								onClick={() => handleGenerateToken()}>
								Resend Code
							</p>
						</span>
					)}
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	data: state.users,
});

export default withRouter(connect(mapStateToProps, { LoginUser })(Login));
