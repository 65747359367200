import DateFnsUtils from "@date-io/date-fns";
import {
	AppBar,
	Checkbox,
	FormControl,
	Input,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	TextField,
	Toolbar,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Delete, Search } from "@material-ui/icons";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import { useEffect, useState } from "react";
import excel from "../../../../assets/icons/Excel2.png";
import { getCustomStatusData } from "../../../../services/admin/reports";
import "./style.scss";

//----------------------------------End of import--------------------------------------------------//

const CustomFilters = (props) => {
	console.log(`CustomFilters props:`, props);

	const [loanStatusOptions, setLoanStatusOptions] = useState([]);
	const [productStatusOptions, setProductStatusOptions] = useState([]);
	const [paymentStatusOptions, setPaymentStatusOptions] = useState([]);
	const [selectedLoanStatus, setSelectedLoanStatus] = useState([]);
	const [selectedProductStatus, setSelectedProductStatus] = useState([]);
	const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);
	const [minOfferAmount, setMinOfferAmount] = useState(0);
	const [maxOfferAmount, setMaxOfferAmount] = useState(0);
	const [minLoanAmount, setMinLoanAmount] = useState(0);
	const [maxLoanAmount, setMaxLoanAmount] = useState(0);
	let fromDefaultDate = new Date();
	fromDefaultDate = fromDefaultDate.setDate(1);
	const [fromDate, setFromDate] = useState(fromDefaultDate);
	const [toDate, setToDate] = useState(new Date().getTime());

	const fetchFiltersValues = async () => {
		let result = await Promise.allSettled([
			await getCustomStatusData({ term: "loanStatus" }),
			await getCustomStatusData({
				term: "productStatus",
			}),
			await getCustomStatusData({
				term: "paymentStatus",
			}),
		]);
		if (result[0].status === "fulfilled") {
			setLoanStatusOptions(result[0].value.status);
			console.log(`result[0].value.status:`, result[0].value.status);
		}
		if (result[1].status === "fulfilled") {
			setProductStatusOptions(result[1].value.status);
		}
		if (result[2].status === "fulfilled") {
			setPaymentStatusOptions(result[2].value.status);
		}
		console.log(`fetchFiltersValues result:`, result);
	};

	useEffect(() => {
		fetchFiltersValues();
	}, []);

	const handleSearch = () => {
		let filterObj = {
			fromDate: moment.unix(fromDate / 1000).format("YYYY-MM-DD"),
			toDate: moment.unix(toDate / 1000).format("YYYY-MM-DD"),
			offerMinAmount: minOfferAmount,
			offerMaxAmonut: maxOfferAmount,
			loanMinAmount: minLoanAmount,
			loanMaxAmount: maxLoanAmount,
			loanStatus: selectedLoanStatus,
			products: selectedProductStatus,
			paymentStatus: selectedPaymentStatus,
		};
		console.log(`handleSearch: `, filterObj);
		props?.handleSearch(filterObj);
	};

	const clearAllFilter = () => {
		setSelectedLoanStatus([]);
		setSelectedProductStatus([]);
		setSelectedPaymentStatus([]);
		setMinOfferAmount(0);
		setMaxOfferAmount(0);
		setMinLoanAmount(0);
		setMaxLoanAmount(0);
		let fromDefaultDate = new Date();
		fromDefaultDate = fromDefaultDate.setDate(1);
		setFromDate(fromDefaultDate);
		setToDate(new Date().getTime());
	};

	const downloadCompleteReport = () => {
		let filterObj = {
			fromDate: moment.unix(fromDate / 1000).format("YYYY-MM-DD"),
			toDate: moment.unix(toDate / 1000).format("YYYY-MM-DD"),
			offerMinAmount: minOfferAmount,
			offerMaxAmonut: maxOfferAmount,
			loanMinAmount: minLoanAmount,
			loanMaxAmount: maxLoanAmount,
			loanStatus: selectedLoanStatus,
			products: selectedProductStatus,
		};
		console.log(`downloadCompleteReport filterObj: `, filterObj);
		props.handleCompleteDownload(filterObj);
	};

	const downloadCustomReport = () => {
		let filterObj = {
			fromDate: moment.unix(fromDate / 1000).format("YYYY-MM-DD"),
			toDate: moment.unix(toDate / 1000).format("YYYY-MM-DD"),
			offerMinAmount: minOfferAmount,
			offerMaxAmonut: maxOfferAmount,
			loanMinAmount: minLoanAmount,
			loanMaxAmount: maxLoanAmount,
			loanStatus: selectedLoanStatus,
			products: selectedProductStatus,
		};
		console.log(`downloadCustomReport filterObj: `, filterObj);
		props.handleCustomDownload(filterObj);
	};

	const downloadInvestorReport = async () => {
		let filterObj = {
			fromDate: moment.unix(fromDate / 1000).format("YYYY-MM-DD"),
			toDate: moment.unix(toDate / 1000).format("YYYY-MM-DD"),
		};

		console.log(`downloadInvestorReport: filterObj: `, filterObj);
		props.handleInvestorReportDownload(filterObj);
	};

	return (
		<>
			<AppBar className="custom-filter-wrapper" position="static">
				{/* date range filter */}
				<p style={{ color: "Black" }}>
					From and To is based on Application creation Date
				</p>
				<Toolbar className="date-range-wrapper">
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<p className="title">From :</p>
						<KeyboardDatePicker
							autoOk={true}
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							value={fromDate}
							onChange={setFromDate}
						/>
						<p className="title">To :</p>
						<KeyboardDatePicker
							autoOk={true}
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							value={toDate}
							onChange={setToDate}
						/>
					</MuiPickersUtilsProvider>

					<Button className="action-wrapper" onClick={downloadCompleteReport}>
						<img className="img-wrapper" src={excel} alt="" />
						Download Complete Custom Report
					</Button>
					<Button className="action-wrapper" onClick={downloadCustomReport}>
						<img className="img-wrapper" src={excel} alt="" />
						Download Custom Report
					</Button>
					<Button className="action-wrapper" onClick={downloadInvestorReport}>
						<img className="img-wrapper" src={excel} alt="" />
						Download Investor Report
					</Button>
				</Toolbar>

				{/* other filters */}
				<Toolbar className="filter-toolbar">
					{/*  loan filter */}
					<FormControl>
						<InputLabel className="loan-status">Loan Status</InputLabel>
						<Select
							className="select-wrapper"
							multiple
							value={selectedLoanStatus}
							onChange={(event) => setSelectedLoanStatus(event.target.value)}
							input={<Input />}
							renderValue={(selected) => {
								let values = selected ?? [];
								console.log(`selected:`, values);
								let tmp = [];
								for (let element of values) {
									let itemValue = loanStatusOptions.find(
										(item) => item.id === element
									);
									tmp.push(itemValue.name);
								}
								return tmp.join(", ");
							}}>
							{loanStatusOptions.length &&
								loanStatusOptions.map((item) => (
									<MenuItem
										className="options-wrapper"
										key={item.id}
										value={item.id}>
										<Checkbox
											checked={selectedLoanStatus.indexOf(item.id) > -1}
										/>
										<ListItemText primary={item.name} />
									</MenuItem>
								))}
						</Select>
					</FormControl>

					{/*  product filter */}
					<FormControl>
						<InputLabel className="product-status">Product Status</InputLabel>
						<Select
							className="select-wrapper"
							multiple
							value={selectedProductStatus}
							onChange={(event) => setSelectedProductStatus(event.target.value)}
							input={<Input />}
							renderValue={(selected) => {
								let values = selected ?? [];
								console.log(`selected:`, values);
								let tmp = [];
								for (let element of values) {
									let itemValue = productStatusOptions.find(
										(item) => item.id === element
									);
									tmp.push(itemValue.name);
								}
								return tmp.join(", ");
							}}>
							{productStatusOptions.length &&
								productStatusOptions.map((item) => (
									<MenuItem
										className="options-wrapper"
										key={item.id}
										value={item.id}>
										<Checkbox
											checked={selectedProductStatus.indexOf(item.id) > -1}
										/>
										<ListItemText primary={item.name} />
									</MenuItem>
								))}
						</Select>
					</FormControl>

					{/* payment filter */}
					<FormControl>
						<InputLabel className="payment-status">Payment Status</InputLabel>
						<Select
							className="select-wrapper"
							multiple
							value={selectedPaymentStatus}
							onChange={(event) => setSelectedPaymentStatus(event.target.value)}
							input={<Input id="select-multiple-chip" />}
							renderValue={(selected) => {
								let values = selected ?? [];
								console.log(`selected:`, values);
								let tmp = [];
								for (let element of values) {
									let itemValue = paymentStatusOptions.find(
										(item) => item === element
									);
									tmp.push(itemValue);
								}
								return tmp.join(", ");
							}}>
							{paymentStatusOptions.map((item) => (
								<MenuItem className="options-wrapper" key={item} value={item}>
									<Checkbox
										checked={selectedPaymentStatus.indexOf(item) > -1}
									/>
									<ListItemText primary={item} />
								</MenuItem>
							))}
						</Select>
					</FormControl>

					{/* offer amount filter */}
					<div className="amount-filter-wrapper">
						<p className="title">Offer Amount:</p>
						<TextField
							className="offer-amount-wrapper"
							type="number"
							placeholder="Min $"
							onChange={(event) =>
								setMinOfferAmount(event.target.value)
							}></TextField>

						<TextField
							className="offer-amount-wrapper"
							type="number"
							placeholder="Max $"
							onChange={(event) =>
								setMaxOfferAmount(event.target.value)
							}></TextField>
					</div>

					{/* loan amount filter */}
					<div className="amount-filter-wrapper">
						<p className="title">Loan Amount:</p>
						<TextField
							className="loan-amount-wrapper"
							type="number"
							placeholder="Min $"
							onChange={(event) =>
								setMinLoanAmount(event.target.value)
							}></TextField>

						<TextField
							className="loan-amount-wrapper"
							type="number"
							placeholder="Max $"
							onChange={(event) =>
								setMaxLoanAmount(event.target.value)
							}></TextField>
					</div>

					<Button
						className="action-wrapper"
						startIcon={<Search />}
						onClick={() => handleSearch()}>
						Search
					</Button>
					<Button
						className="action-wrapper"
						startIcon={<Delete />}
						onClick={() => clearAllFilter()}>
						Reset
					</Button>
				</Toolbar>
			</AppBar>
		</>
	);
};
export default CustomFilters;
