import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from "@coreui/react";
import axios from "axios";
import { createBrowserHistory } from "history";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from "react-router-dom";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/fixed";
import { checkAuth } from "../actions/users";
import Filter from "../components/filter";
import CNavbar from "../components/nav";
import TheLayout from "../containers/TheLayout";
import Reports from "../page/admin/reports/index";
import ForgetPassword from "../page/auth/forgetPassword";
import Login from "../page/auth/login";
import ResetPassword from "../page/auth/resetPassword";
import { headers } from "../reusable/headers";
import { pageStyles } from "../style/pageStyles";

const history = createBrowserHistory();

const NavigationRoute = ({ users, role }) => {
	const classes = pageStyles();
	// eslint-disable-next-line 
	const [filterUser, setFilterUser] = React.useState([]);
	const [loggedIn, setLoggedIn] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const logoutUser = async () => {
		try {
			await axios.get(`${process.env.REACT_APP_API_HOST}/V1/admin/logout`, {
				headers: headers(),
			});
			window.location.href = `/login`;
			sessionStorage.removeItem("_st");
		} catch (e) {
			console.log("Error while logging out", e.message);
		}
	};

	const onPrompt = () => {
		if (loggedIn) {
			setShowModal(true);
		}
	};

	const onIdle = async () => {
		if (loggedIn) {
			setShowModal(false);
			await logoutUser();
		}
	};

	const { reset } = useIdleTimer({
		onPrompt,
		onIdle,
		timeout:
			1000 *
			60 *
			parseInt(process.env.REACT_APP_USER_IDLE_PROMPT_SHOWTIME || "17"),
		promptTimeout:
			1000 *
			60 *
			parseInt(process.env.REACT_APP_USER_IDLE_PROMPT_TIMEOUT || "3"),
		events: [
			"mousemove",
			"keydown",
			"wheel",
			"DOMMouseScroll",
			"mousewheel",
			"mousedown",
			"touchstart",
			"touchmove",
			"MSPointerDown",
			"MSPointerMove",
			"visibilitychange",
		],
		immediateEvents: [],
		debounce: 0,
		throttle: 0,
		eventsThrottle: 200,
		element: document,
		startOnMount: true,
		startManually: false,
		stopOnIdle: false,
		crossTab: false,
		syncTimers: 0,
	});

	const resumeActivity = () => {
		reset();
		setShowModal(false);
	};
	let allowedPageLocations = [
		"/login",
		"/generateToken",
		"/forgetPassword",
		"/resetPassword",
	];
	// Exclude public pages for authentication
	useEffect(() => {
		const interval = setIntervalAsync(async () => {
			const authStatus = await checkAuth();
			if (document.visibilityState === "visible" && authStatus) {
				await axios.post(
					`${process.env.REACT_APP_API_HOST}/V1/admin/sendHeartbeat`,
					{},
					{ headers: headers() }
				);
			} else if (
				!authStatus &&
				!allowedPageLocations.includes(window.location.pathname)
			) {
				await logoutUser();
			}
		}, 10000);
		return () => clearIntervalAsync(interval);
		// eslint-disable-next-line 
	}, []);

	const pathName = window.location.pathname;
	const search = window.location.search;
	console.log("pathName: ", pathName);
	console.log("search: ", search);

	useEffect(() => {
		const getAuthStatus = async () => {
			const res = await checkAuth();
			setLoggedIn(res);
		};
		getAuthStatus();
	});

	const filterCallBack = (result) => {
		setFilterUser(result);
	};

	return (
		<>
			<div>
				<Router history={history}>
					{loggedIn && <CNavbar />}
					<Switch>
						<main>
							<div className={classes.pad} />
							{loggedIn ? (
								<>
									<Route
										path="/origination"
										exact
										render={(props) => <TheLayout {...props} />}
									/>
									<Route
										path="/collections"
										exact
										render={(props) => <TheLayout {...props} />}
									/>
									<Route
										path="/underwriting"
										render={(props) => <TheLayout {...props} />}
									/>
									<Route
										path="/funding"
										render={(props) => <TheLayout {...props} />}
									/>
									<Route
										path="/servicing"
										render={(props) => <TheLayout {...props} />}
									/>
									<Route
										path="/completed"
										render={(props) => <TheLayout {...props} />}
									/>
									<Route
										path="/archived"
										render={(props) => <TheLayout {...props} />}
									/>

									<Route
										path="/banking"
										render={(props) => <TheLayout {...props} />}
									/>
									<Route path="/report" render={() => <Reports />} />
									<Route
										path="/users"
										render={(props) => <TheLayout {...props} />}
									/>

									<Route
										path="/admin"
										name="Home"
										render={(props) => <TheLayout {...props} />}
									/>
									<Route
										path="/customer"
										render={(props) => <TheLayout {...props} />}
									/>
									<Route path="/filter">
										<Filter user={users} filterCallBack={filterCallBack} />
									</Route>
								</>
							) : (
								<>
									{pathName === "/resetPassword" && (
										<Redirect from="/" to={`/resetPassword${search}`} />
									)}
									{pathName === "/forgetPassword" && (
										<Redirect from="/" to={`/forgetPassword`} />
									)}
									{pathName === "/" && <Redirect from="/" to="/login" />}
								</>
							)}
							<Route
								path="/resetPassword"
								render={(props) => <ResetPassword {...props} />}
							/>
							<Route
								path="/forgetPassword"
								render={(props) => <ForgetPassword {...props} />}
							/>
							<Route
								path="/login"
								render={(props) =>
									!loggedIn ? (
										<Login {...props} />
									) : (
										<Redirect from="/" to="/origination" />
									)
								}
							/>
						</main>
					</Switch>
				</Router>
			</div>
			<div>
				<CModal show={showModal} onClose={() => setShowModal(!showModal)}>
					<CModalHeader closeButton>
						<CModalTitle>User Inactivity Alert</CModalTitle>
					</CModalHeader>
					<CModalBody>
						You seem to be inactive. We are logging you out in a minute. Press
						OK to resume.
					</CModalBody>
					<CModalFooter>
						<CButton color="secondary" onClick={resumeActivity}>
							OK
						</CButton>
					</CModalFooter>
				</CModal>
			</div>
		</>
	);
};

export default NavigationRoute;
