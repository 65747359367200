import { Button } from "@material-ui/core";
import React, { useState } from "react";
import ManualPAPForm from "./manualPAPGeneration";
import { runPaymentJob } from "../../../actions/users";

export default function PAPGeneration() {
	const [processingDate, setProcessingDate] = useState("");
	const [fileName, setFileName] = useState("");
	const [userSelection, setUserSelection] = useState(""); // New state for user selection

	const runPaymentJobClick = async () => {
		if (processingDate === "") {
			alert("Please Select Date");
			return;
		}
		if (userSelection === "process with file" && fileName === "") {
			alert("Please Enter File Name");
			return;
		}
		const data = {
			processingDate: processingDate,
			fileName: fileName,
		};
		await runPaymentJob(data);
		alert("Cron Job Executed");
	};

	const handleProcessingDateChange = (e) => {
		setProcessingDate(e.target.value);
	};

	const handleFileNameChange = (e) => {
		setFileName(e.target.value);
	};

	const handleUserSelectionChange = (e) => {
		// New handler for user selection
		setUserSelection(e.target.value);
	};

	const styles = {
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			padding: "20px",
		},

		input: {
			marginBottom: "10px",
			padding: "8px",
			borderRadius: "4px",
			border: "1px solid #ccc",
			fontSize: "14px",
		},
		button: {
			padding: "10px 20px",
			borderRadius: "4px",
			background: "#007bff",
			color: "#fff",
			border: "none",
			fontSize: "16px",
			cursor: "pointer",
		},
	};

	return (
		<div>
			<ManualPAPForm />
			<br />
			<br />
			<br />
			<div style={styles.container}>
				<select
					value={userSelection}
					onChange={handleUserSelectionChange}
					style={styles.input}>
					<option value="Without File" default>
						Without File
					</option>

					<option value="process with file">Process with File</option>
				</select>
				<input
					type="date"
					value={processingDate}
					onChange={handleProcessingDateChange}
					style={styles.input}
				/>
				{userSelection === "process with file" && ( // Conditionally render filename input
					<input
						type="text"
						value={fileName}
						onChange={handleFileNameChange}
						style={styles.input}
						placeholder="Enter filename"
					/>
				)}
				<Button onClick={runPaymentJobClick} style={styles.button}>
					Run Payment Workflow
				</Button>
			</div>
		</div>
	);
}
