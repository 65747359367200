import * as actionTypes from "../actions/actionTypes";

const initialState = {
	authenticated: false,
	loading: false,
	data: [],
	success_message: undefined,
	success: false,
	error: undefined,
	rm_loading: false,
};

const roles = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_RESPONSE_ERROR:
			return {
				...state,
				rm_loading: false,
				loading: false,
				success: false,
				error: action.payload.response && action.payload.response.data.error,
			};
		case actionTypes.CREATE_ROLE_SUCCESS:
			return {
				...state,
				authenticated: true,
				loading: false,
				success: true,
				success_message: "Successfully created.",
			};
		case actionTypes.UPDATE_ROLE_SUCCESS:
			return {
				...state,
				authenticated: true,
				loading: false,
				success: true,
				success_message: "Successfully updated.",
			};
		case actionTypes.GET_ROLES_SUCCESS:
			return {
				...state,
				authenticated: true,
				loading: false,
				success: true,
				success_message: "All roles.",
				data: action.payload.response.roles,
			};
		case actionTypes.CLEAR_ERROR:
			return {
				...state,
				loading: false,
				success: false,
				success_message: undefined,
				error: undefined,
			};
		case actionTypes.STOP_LOADER:
			return { ...state, rm_loading: false, loading: false };
		default:
			return { ...state, loading: false };
	}
};

export default roles;
