import "date-fns";
import * as moment from "moment";
import { useEffect, useState } from "react";
import {
	getPortfolioData,
	getPortfolioInterestData,
	getPortfolioLoansData,
	getPortfolioScoreData,
} from "../../../../services/admin/reports";
import PortfolioAppBar from "./AppBar";
import Chart from "./Chart";
import Data from "./Data";
import PortfolioTable from "./Table";
//----------------------------------End of import--------------------------------------------------//

export default function ReportPortfolio() {
	const [term, setTerm] = useState("Daily");
	let firstDateOfMonth = new Date().setDate(1);
	const [fromDate, setFromDate] = useState(firstDateOfMonth);
	const [toDate, setToDate] = useState(new Date());
	const [chartOnly, setChartOnly] = useState(true);
	const [tableOnly, setTableOnly] = useState(true);
	const [reportData, setReportData] = useState([]);
	const [reportInterestData, setReportInterestData] = useState([]);
	const [rateSum, setRateSum] = useState(0);
	const [reportAcceptedScoreData, setReportAcceptedScoreData] = useState([]);
	const [reportRejectedScoreData, setReportRejectedScoreData] = useState([]);
	const [averagValue, setAverageValue] = useState({ sumOfFico: 0, sumOfQ: 0 });
	const [portfolioAverageYield, setPortfolioAverageYield] = useState({
		personalsumOfColumnD: 0,
		studentSumOfColumnD: 0,
		refinancingSumOfColumnD: 0,
	});
	const [rejectedSumValue, setRejectedsumValue] = useState({
		sumOfFico: 0,
		sumOfQ: 0,
	});
	// const [rejectedSumValue, setRejectedsumValue] = useState(0);
	const [loans, setLoans] = useState({
		studentLoan: [],
		personalLoan: [],
		refinancingLoan: [],
	});
	const [sumOfLoans, setSumOfLoans] = useState({
		sumOfPersonalLoan: 0,
		sumOfStudentLoan: 0,
		sumOfRefinancingloan: 0,
		totalLoan: 0,
		personalSumOfColumnG: 0,
		studentSumOfColumnG: 0,
		refinancingSumOfColumnG: 0,
	});

	useEffect(() => {
		async function fetchreportdata() {
			let data = {
				term: term,
				fromdate: fromDateForm(fromDate),
				todate: fromDateForm(toDate),
			};
			let reportResult = await getPortfolioData(data);
			if (reportResult.success) {
				setReportData(reportResult.reports);
			}
			// console.log("reportlist", reportResult.reports);
		}

		fetchreportdata();
	}, [term, fromDate, toDate]);

	async function fetchReportInterestData() {
		const portfolioInterestResult = await getPortfolioInterestData();
		let interest = [];
		if (portfolioInterestResult.length > 0) {
			// setReportInterestData(portfolioInterestResult);
			interest = portfolioInterestResult;
		}
		// console.log("Report Interest " + portfolioInterestResult.reports[0]);
		setReportInterestData(interest);
		// console.log("Report Interest " + portfolioInterestResult.length);

		let rate = 0;

		interest &&
			// eslint-disable-next-line
			interest?.map((item, index) => {
				let data = item && item.productRate;
				// rate = rate + data;
				rate += data;
			});
		// setReportInterestData(rate);
		setRateSum(rate);
	}
	// console.log("Report interest Data length"+reportInterestData.length)
	useEffect(() => {
		fetchReportInterestData();
		fetchReportScore();
		// eslint-disable-next-line
	}, []);

	async function fetchReportScore() {
		const portfolioScoreResult = await getPortfolioScoreData();
		let reportacceptedScoreData = [];
		let reportrejectedScoreData = [];
		if (portfolioScoreResult.success) {
			reportacceptedScoreData = portfolioScoreResult.accepted;
			reportrejectedScoreData = portfolioScoreResult.rejected;
			setReportAcceptedScoreData(portfolioScoreResult.accepted);
			setReportRejectedScoreData(portfolioScoreResult.rejected);
		}
		// console.log(portfolioScoreResult.accepted);
		console.log("portfolioScoreResult" + portfolioScoreResult);
		console.log("Rejected => " + portfolioScoreResult.rejected);

		let sum = 0;
		let qSum = 0;
		reportacceptedScoreData &&
			// eslint-disable-next-line
			reportacceptedScoreData?.map((item, index) => {
				let data = item && item["financial_score"];
				let qData = item && item["Total-initial-score"];
				sum = sum + data;
				qSum = qSum + qData;
				setAverageValue({ sumOfFico: sum, sumOfQ: qSum });
			});
		// console.log(sum)
		// let rejectedSum = 0;
		let frejectedSum = 0;
		let qrejectedSum = 0;
		reportrejectedScoreData &&
			// eslint-disable-next-line
			reportrejectedScoreData?.map((item, index) => {
				// let data = item ;
				// rejectedSum = rejectedSum + data;
				// setRejectedsumValue(rejectedSum);

				let data = item && item["financial_score"];
				let qData = item && item["Total-initial-score"];
				frejectedSum = frejectedSum + (data || 0);
				qrejectedSum = qrejectedSum + (qData || 0);
				setRejectedsumValue({ sumOfFico: frejectedSum, sumOfQ: qrejectedSum });
			});
		console.log("trejected Sum" + rejectedSumValue);
	}

	useEffect(() => {
		async function fetchLoansData() {
			const portfolioLoansResult = await getPortfolioLoansData();
			if (portfolioLoansResult.success) {
				setLoans({
					studentLoan: portfolioLoansResult.studentLoans,
					personalLoan: portfolioLoansResult.personalLoans,
					refinancingLoan: portfolioLoansResult.refinancingLoans,
				});
			}
			// console.log("Loan" + portfolioLoansResult);
		}

		fetchLoansData();
	}, []);

	let sumPersonal = 0;
	let sumRefinancing = 0;
	let sumStudent = 0;

	const sumOfLoansMap = () => {
		loans.personalLoan &&
			// eslint-disable-next-line
			loans.personalLoan?.map((item, index) => {
				let data = item && item["productAmount"].toString().replace(/,/g, "");
				sumPersonal += Number(data);
			});

		loans.studentLoan &&
			// eslint-disable-next-line
			loans.studentLoan?.map((item2, index) => {
				let data = item2 && item2["productAmount"].toString().replace(/,/g, "");
				sumStudent += Number(data);
			});

		loans.refinancingLoan &&
			// eslint-disable-next-line
			loans.refinancingLoan?.map((item3, index) => {
				let data = item3 && item3["productAmount"].toString().replace(/,/g, "");
				sumRefinancing += Number(data);
			});
	};

	const calculateAverageYield = () => {
		let sumOfColumnG = 0;
		let studentSumOfG = 0;
		let refinancingSumOfG = 0;

		let personalSumColumnD = 0;
		let studentSumClumnD = 0;
		let refinanceSumColumnD = 0;

		loans.personalLoan &&
			loans.personalLoan?.map(
				// eslint-disable-next-line
				(item, index) => {
					let columnF = 0;
					let columnG = 0;

					let columnD = 0;
					let columnE = 0;

					let data = item && item["productAmount"].toString().replace(/,/g, "");
					let rate = item && item["productRate"];
					// console.log("item", item);

					columnD = Number(data) / (sumPersonal + sumStudent + sumRefinancing);
					columnE = (columnD * Number(rate)) / 100;
					personalSumColumnD += columnE;

					columnF = Number(data) / sumPersonal;
					columnG = (columnF * Number(rate)) / 100;
					sumOfColumnG = sumOfColumnG + columnG;
				},
				loans.studentLoan &&
					// eslint-disable-next-line
					loans.studentLoan?.map((item, index) => {
						let columnF = 0;
						let columnG = 0;

						let columnD = 0;
						let columnE = 0;

						let data =
							item && item["productAmount"].toString().replace(/,/g, "");
						let rate = item && item["productRate"];

						columnD =
							Number(data) / (sumPersonal + sumStudent + sumRefinancing);
						columnE = (columnD * Number(rate)) / 100;
						studentSumClumnD += columnE;

						columnF = Number(data) / sumStudent;
						columnG = (columnF * Number(rate)) / 100;
						studentSumOfG = studentSumOfG + columnG;
					}),

				loans.refinancingLoan &&
					// eslint-disable-next-line
					loans.refinancingLoan?.map((item, index) => {
						let columnF = 0;
						let columnG = 0;

						let columnD = 0;
						let columnE = 0;

						let data =
							item && item["productAmount"].toString().replace(/,/g, "");
						let rate = item && item["productRate"];

						columnD =
							Number(data) / (sumPersonal + sumStudent + sumRefinancing);
						columnE = (columnD * Number(rate)) / 100;
						refinanceSumColumnD += columnE;

						columnF = Number(data) / sumRefinancing;
						columnG = (columnF * Number(rate)) / 100;
						refinancingSumOfG = refinancingSumOfG + columnG;
					})
			);

		setPortfolioAverageYield({
			...portfolioAverageYield,
			personalsumOfColumnD: personalSumColumnD,
			studentSumOfColumnD: studentSumClumnD,
			refinancingSumOfColumnD: refinanceSumColumnD,
		});

		setSumOfLoans({
			...sumOfLoans,
			sumOfPersonalLoan: sumPersonal,
			sumOfStudentLoan: sumStudent,
			sumOfRefinancingloan: sumRefinancing,
			totalLoan: sumPersonal + sumStudent + sumRefinancing,
			personalSumOfColumnG: sumOfColumnG,
			studentSumOfColumnG: studentSumOfG,
			refinancingSumOfColumnG: refinancingSumOfG,
		});
	};
	useEffect(() => {
		sumOfLoansMap();
		calculateAverageYield();
		// eslint-disable-next-line
	}, [loans.personalLoan, loans.studentLoan, loans.refinancingLoan]);

	const fromDateForm = (date) => {
		const formattedDate = moment(date).format("YYYY-MM-DD");
		return formattedDate;
	};
	// console.log("sumOfLoans", sumOfLoans);
	return (
		<div
			className="sidebar-content-container"
			style={{ height: "0%", width: "88%" }}>
			<div>
				<PortfolioAppBar
					term={term}
					setTerm={setTerm}
					fromDate={fromDate}
					setFromDate={setFromDate}
					toDate={toDate}
					setToDate={setToDate}
					chartOnly={chartOnly}
					setChartOnly={setChartOnly}
					tableOnly={tableOnly}
					setTableOnly={setTableOnly}
					reportData={reportData}
				/>
			</div>
			<div>
				<Data
					rateSum={rateSum}
					reportInterestData={reportInterestData}
					averagValue={averagValue}
					reportAcceptedScoreData={reportAcceptedScoreData}
					reportRejectedScoreData={reportRejectedScoreData}
					rejectedSumValue={rejectedSumValue}
					loans={loans}
					sumOfLoans={sumOfLoans}
					portfolioAverageYield={portfolioAverageYield}
				/>
			</div>

			{chartOnly && (
				<div className="admin-card">
					<Chart
						reportData={reportData}
						setReportData={setReportData}
						sumOfLoans={sumOfLoans}
					/>
				</div>
			)}

			{tableOnly && (
				<div className="admin-card">
					<PortfolioTable
						reportData={reportData}
						setReportData={setReportData}
					/>
				</div>
			)}
		</div>
	);
}
