import React from "react";
import { TheContent, TheSidebar} from "./index";

const TheLayout = (props) => {
	return (
		<div className="c-app c-default-layout">
			{props.location.pathname.includes("/admin")  && <TheSidebar />}
			<div className="c-wrapper">
				{/* <TheHeader/> */}
				<div className="c-body">
					<TheContent />
				</div>
				{/* <TheFooter/> */}
			</div>
		</div>
	);
};

export default TheLayout;
