import {
	CButton,
	CCard,
	CCardBody,
	CCardHeader,
	CCol,
	CDataTable,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import {
	decryptfileContent,
	getModelAllFiles,
} from "../../../services/admin/modelDecrypt";
import { formatDate } from "../../../utils";

export default function ModelDecrypt(props) {
	const [modelData, setModelData] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [decryptInfo, setDecryptInfo] = useState("");
	const [apiLoader, setApiLoader] = useState(false);

	const rowClick = async (row, event) => {
		const decrypt = await decryptfileContent(row.fileName);
		setDecryptInfo(JSON.stringify(decrypt));
		setShowModal(true);
	};

	const fields = [
		{ key: "id", label: "#" },
		{ key: "fileName", label: "File Name" },
		{ key: "created_at", label: "Created At" },
	];

	useEffect(() => {
		async function fetchData() {
			setApiLoader(true);
			const res = await getModelAllFiles();
			setModelData(res);
			setApiLoader(false);
		}
		fetchData();
	}, []);

	return (
		<CRow>
			<CCol>
				{apiLoader ? <div className="tableLoader">Processing...</div> : null}
				<CCard>
					<CCardHeader className="flex userHeader">
						<h4>Models</h4>
					</CCardHeader>
					<CCardBody>
						{modelData && (
							<CDataTable
								items={modelData}
								fields={fields}
								tableFilter
								itemsPerPageSelect
								sorter
								hover
								striped
								bordered
								itemsPerPage={50}
								pagination
								onRowClick={rowClick}
								scopedSlots={{
									id: (item, index) => (
										<td>
											<p>{index + 1}</p>
										</td>
									),
									name: (item) => (
										<td>
											<p>{item.fileName}</p>
										</td>
									),
									created_at: (item) => (
										<td>
											<p>{formatDate(item.created_at)}</p>
										</td>
									),
								}}
							/>
						)}
					</CCardBody>
					<CModal show={showModal} onClose={() => setShowModal(!showModal)}>
						<CModalHeader closeButton>
							<CModalTitle>Decrypted Model</CModalTitle>
						</CModalHeader>
						<CModalBody>{decryptInfo}</CModalBody>
						<CModalFooter>
							<CButton
								color="secondary"
								onClick={() => setShowModal(!showModal)}>
								OK
							</CButton>
						</CModalFooter>
					</CModal>
				</CCard>
			</CCol>
		</CRow>
	);
}
