import { Table, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { OperationalStyle } from "../Style/Style";

//------------------------------------------End of Import ---------------------------------------------------//

export default function RiskData(props) {
	const loanriskStyle = OperationalStyle();
	const total = props.riskData.early + props.riskData.onTime + props.riskData.late;
	const earlyPercentage = (props.riskData.early / total).toFixed(2) * 100
	const onTimePercentage = (props.riskData.onTime / total).toFixed(2) * 100
	const latePercentage = (props.riskData.late / total).toFixed(2) * 100


	return (
		<div className="admin-card custom-form-table">
			<Table id={props.name}>
				<Typography className={loanriskStyle.HeadingBorderStyle}>
					Loan Returns
				</Typography>

				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography>Early:</Typography>
								<Typography>{props.riskData.early}</Typography>
							</TableCell>
							<TableCell>
								<Typography>On Time:</Typography>
								<Typography>{props.riskData.onTime}</Typography>
							</TableCell>
							<TableCell>
								<Typography>Late:</Typography>
								<Typography>{props.riskData.late}</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>

				<Typography
					className={loanriskStyle.HeadingBorderStyle}
					style={{ marginTop: "10px" }}>
					Percentage %
				</Typography>
				<Table>
					<TableHead>
						<TableRow>
						<TableCell style={{ width: "31%" }}>
								<Typography>Early %:</Typography>
								<Typography>{isNaN(earlyPercentage ) ? 0 : earlyPercentage}</Typography>
							</TableCell>
							<TableCell style={{ width: "40.6% " }}>
								<Typography>On Time %:</Typography>
								<Typography>{isNaN(onTimePercentage) ? 0 : onTimePercentage}</Typography>
							</TableCell>
							<TableCell>
								<Typography>Late %:</Typography>
								<Typography>{isNaN(latePercentage) ? 0 : latePercentage}</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</Table>
		</div>
	);
}
