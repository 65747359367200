import { Redirect, Route, Switch } from "react-router-dom";
import Behavioral from "./Behavioral/index";
import Operational from "./Operational/index";
import ReportPortfolio from "./Portfolio/index";
import SideBar from "./SideBar/SideBar";
import Risks from "./Risks/index";
import CustomerAnalytics from "./CustomerAnalytics";
import Custom from "./Custom";
export default function Reports() {
	return (
		<div class="admin-wrapper-sidebar">
			<SideBar />
			<Switch>
				<Route
					exact
					path="/report/portfolio"
					render={(props) => <ReportPortfolio {...props} />}
				/>
				<Route exact path="/report/operational" component={Operational} />
				<Route exact path="/report/risks" component={Risks} />
				<Route exact path="/report/behavioral" component={Behavioral} />
				<Route
					exact
					path="/report/customeranalytics"
					component={CustomerAnalytics}
				/>
				<Route exact path="/report/custom" component={Custom} />
				<Redirect to="/report/portfolio" />
			</Switch>
		</div>
	);
}
