export const GET_RESPONSE_ERROR = "GET_RESPONSE_ERROR";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_ROLES_DATA = "GET_ROLES_DATA";
export const GET_USERS_DATA = "GET_USERS_DATA";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_APPLICATIONS_SUCCESS = "GET_APPLICATIONS_SUCCESS";
export const GET_APPLICATION_DATA_SUCCESS = "GET_APPLICATION_DATA_SUCCESS";
export const GET_APPLICATION_HISTORY_SUCCESS =
	"GET_APPLICATION_HISTORY_SUCCESS";
export const GET_INTERACTION_HISTORY_SUCCESS =
	"GET_INTERACTION_HISTORY_SUCCESS";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const UPDATE_APPLICATIONS_STATUS_SUCCESS =
	"UPDATE_APPLICATIONS_STATUS_SUCCESS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const CUSTOM_ERROR = "CUSTOM_ERROR";

export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const CREATE_PAYEE = "CREATE_PAYEE";

export const GET_DB_DATA = "GET_DB_DATA";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const REMEMBER_TOKEN_SUCCESS = "REMEMBER_TOKEN_SUCCESS";
export const GET_RM_REQUEST = "GET_RM_REQUEST";

export const STOP_LOADER = "STOP_LOADER";
