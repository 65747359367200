import axios from "axios";
import download from "downloadjs";
import { toast } from "react-toastify";
import { headers } from "../../reusable/headers";

export const createActionPlan = async (data) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/action/create`,
			data: data,
			headers: headers(),
		});
		if (response.data.success) {
			toast.success("Action added successfully");
		}
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getActionList = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/action/${appId}`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getDocuments = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/documents/${appId}`, //idBw48c0ZDHG9TvvK2ts
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getVouchedData = async (appId, status) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/voucedDocs/${appId}/${status}`, //idBw48c0ZDHG9TvvK2ts
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const getMonthlyStatement = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/getMonthlyPayments/${appId}`, //idBw48c0ZDHG9TvvK2ts
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getReRequestData = async (currentSelect) => {
	const ReRequested = await axios.post(
		`${process.env.REACT_APP_API_HOST}/V1/admin/getrerequest`,
		{ currentSelect: currentSelect },
		{ headers: headers() }
	);
	return ReRequested.data.result;
};

export const getUserUploadedDocuments = async (appId) => {
	const userDocs = await axios.get(
		`${process.env.REACT_APP_API_HOST}/V1/admin/getUserUploadedDocuments/${appId}`,
		{ headers: headers() }
	);
	return userDocs.data.result;
};

export const getAdminUnreadMessageCount = async (userId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/unreadmessageCount/${userId}`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getNewVouchedData = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/vouchedData/${appId}`, //idBw48c0ZDHG9TvvK2ts
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const resendVerificationMail = async (userId) => {
	try {
		const getUser = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/resendVerificationMail/${userId}`,
			headers: headers(),
		});
		return getUser.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const updateVouchedData = async (appId, status, updateStatus) => {
	try {
		const response = await axios({
			method: "PUT",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/voucedDocs/${appId}/${status}/${updateStatus}`, //idBw48c0ZDHG9TvvK2ts
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const reRequestDoc = async (appId, status) => {
	try {
		const response = await axios({
			method: "PUT",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/requestDoc/${appId}/${status}`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getDocumentsbyIdType = async (appId, type) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/documents/download/${appId}/${type}`, //idBw48c0ZDHG9TvvK2ts
			data: { appId: appId, type: type },
			headers: headers(),
		});
		console.log("res", response);
		const blob = await response.data.blob();
		download(blob, "test.pdf");
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getSystemDocuments = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/documents/system/list`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const createDocReq = async (data) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/docreq/create`,
			data: data,
			headers: headers(),
		});
		// if (response.data.success) {
		//   toast.success("Action added successfully");
		// }
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getUsersDocuments = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/userDocuments/${appId}`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const uploadSystemDocumentImage = async (data) => {
	try {
		let headersObj = headers();
		headersObj["Content-Type"] = "multipart/form-data";
		console.log("hea", headersObj, data);
		const response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/admin/documents/system/upload`,
			data,
			{ headers: headersObj }
		);
		if (response.data.success) {
			toast.success(response.data.message);
		}
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const generateStatement = async (data) => {
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/application/createMonthlyStatementByAppid`,
			data,
			{ headers: headers() }
		);
		if (response.data.success) {
			toast.success(response.data.message);
		}
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const createPaymentSchedule = async (data) => {
	try {
		console.log("crae pat data", data);
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/schedule/create`,
			headers: headers(),
			data: data,
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getPaymentsSchedule = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/schedule/${appId}`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const getAutoPay = async (appId) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/banking/autopay`,
			data: { appId: appId },
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getAutoPayHistory = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/banking/getAutoPayHistory/${appId}`,
			headers: headers(),
		});
		return response.data.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getInstallments = async (loanAmount, loanTerm, interest) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/application/getInstallments/${loanAmount}/${loanTerm}/${interest}`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getInitialPaymentsSchedule = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/applications/getInitialSchedule/${appId}`,
			headers: headers(),
		});

		return { payments: response.data.data };
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const getLedgersList = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/applications/getledger/${appId}`, //idBw48c0ZDHG9TvvK2ts
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getUserIpLogs = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/application/getUserIpLogs/${appId}`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const updatePaymentSchedule = async (data) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/applications/update/Initialschedule`,
			data: data,
			headers: headers(),
		});

		return { payments: response.data.data };
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const updateLoanDetials = async (data) => {
	try {
		console.log("update pay data ==- -- ==", data);
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/applications/update/loanDetails`,
			headers: headers(),
			data: data,
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const rejectApplication = async (data) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/applications/reject`,
			headers: headers(),
			data: data,
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const getExtraPaymentData = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/extrapayment/${appId}`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const deleteExtraSchedulePayment = async (PaymentId) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/extrapayment/delete`,
			headers: headers(),
			data: { PaymentId: PaymentId },
		});
		toast.error(response.data.message);
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getFilesData = async (type) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/papfiles/${type}`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const createExtraPaymentData = async (data) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/extrapayment/create`,
			headers: headers(),
			data: data,
		});
		toast.success(response.data.message);
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const updateExtraPaymentData = async (paymentId, data) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/extrapayment/update`,
			headers: headers(),
			data: { paymentId: paymentId, ...data },
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const writeOffApp = async (data) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/applications/writeoff`,
			headers: headers(),
			data: data,
		});
		toast.success("Application writeoff successfully");
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const getPayoff = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/payoffAmount/${appId}`,
			headers: headers(),
		});

		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const doPayOff = async (data) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/doPayOff`,
			headers: headers(),
			data: data,
		});
		toast.success("Payoff successfully");
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const freezLoanApp = async (data) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/applications/freeze`,
			headers: headers(),
			data: data,
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getUserApplications = async (userId) => {
	try {
		const result = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/application/getUserApplications/${userId}`,
			headers: headers(),
		});
		console.log("applications result", result);
		return result.data;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getActiveApplication = async (appId) => {
	try {
		const Result = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/applications/${appId}`,
			headers: headers(),
		});
		console.log("getActiveApplication", Result);
		return Result.data.application;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const updateFundLoan = async (appId) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/applications/fundLoan/${appId}`,
			data: { appId: appId },
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const pauseCollectionCall = async (appId) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/applications/pauseCollection`,
			data: { appId: appId },
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};


export const getUsersConsent = async (appId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/usersConsent/${appId}`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const uploadGlobalFilesData = async (data) => {
	try {
		let headersObj = headers();
		headersObj["Content-Type"] = "multipart/form-data";
		const response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/admin/update/updateGlobalFileData`,
			data,
			{ headers: headersObj }
		);
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getSummaryData = async (appId, userId) => {
	try {
		const response = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/summary/${appId}/${userId}`,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};
export const loanDocuments = async (data) => {
	try {
		let headersObj = headers();
		headersObj["Content-Type"] = "multipart/form-data";

		const response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/admin/loanDocuments/uploads`,
			data,
			{ headers: headersObj }
		);
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getUserBankDetails = async (userId) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/application/bankinfo`,
			data: { userId },
			headers: headers(),
		});
		if (response.data.success) {
			return response.data.data[0];
		} else {
			return false;
		}
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const refreshPlaid = async (appId) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/plaidrefresh`,
			data: { appId: appId },
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const bankDataUpdate = async (data) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/application/bankinfoupdate`,
			data: data,
			headers: headers(),
		});
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getFundingDocuments = async (appId) => {
	const userDocs = await axios.get(
		`${process.env.REACT_APP_API_HOST}/V1/admin/fundingDocuments/${appId}`,
		{
			headers: headers(),
		}
	);
	return userDocs.data.result;
};

export const switchAutoDebit = async (data) => {
	try {
		let response = await axios.post(
			`${process.env.REACT_APP_API_HOST}/V1/admin/banking/autoDebit`,
			data,
			{ headers: headers() }
		);
		return response.data;
	} catch (e) {
		console.log(e);
		throw new ErrorEvent(e.message);
	}
};

export const getPaymentDocuments = async (userId) => {
	const userDocs = await axios.get(
		`${process.env.REACT_APP_API_HOST}/V1/admin/paymentDocuments/${userId}`,
		{ headers: headers() }
	);
	return userDocs.data.result;
};

export const deleteApplication = async (appId) => {
	try {
		const response = await axios({
			method: "POST",
			url: `${process.env.REACT_APP_API_HOST}/V1/admin/deleteApplication`,
			headers: headers(),
			data: { appId: appId },
		});
		toast.success(response.data.message);
		return response.data;
	} catch (e) {
		throw new ErrorEvent(e.message);
	}
};

export const getApplicationData = async (appId) => {
	const result = await axios.get(
		`${process.env.REACT_APP_API_HOST}/V1/admin/getApplicationData/${appId}`,
		{
			headers: headers(),
		}
	);
	return result.data.result;
};

export const getMissingBankInfoApplicationsCount = async () => {
	const result = await axios.get(
		`${process.env.REACT_APP_API_HOST}/V1/admin/getMissingBankInfoApplicationsCount`,
		{
			headers: headers(),
		}
	);
	return result.data.count;
};
