import React, { useState } from "react";
import {
	CountryDropdown,
	RegionDropdown,
} from "react-country-region-selector";
import {
	Card,
	CardContent,
	CardHeader,
	Paper,
	Slider,
	RadioGroup,
	FormControlLabel,
	Radio,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

export default function Filter({ user, filterCallBack }) {
	const [country, setCountry] = useState("Canada");
	const [province, setProvince] = useState("Ontario");
	const [product, setProduct] = useState("");
	const [issueYear, setIssueYear] = useState(2000);
	const [currency, setCurrency] = useState("CAD");
	const currentYear = new Date().getFullYear();
	const history = useHistory();

	const handleYearChange = (event, newYear) => {
		setIssueYear(newYear);
	};

	const handleSelectCurrency = (event, newCurrency) => {
		setCurrency(newCurrency);
	};

	const handleSelectProduct = (event, newProduct) => {
		setProduct(newProduct);
	};

	const applyFilter = () => {
		// eslint-disable-next-line 
		const filter = user.filter((result) => {
			if (
				result.address.Country === country &&
				result.address.Province === province.slice(0, 2).toUpperCase() &&
				result.loan_detail.loan_product === product &&
				result.loan_detail.issueYear === issueYear &&
				result.loan_detail.currency === currency
			) {
				return result;
			}
		});

		filterCallBack(filter);
		history.push("/");
	};

	const cleanFilter = () => {
		setCountry("Canada");
		setProvince("Ontario");
		setProduct("");
		setIssueYear(2000);
		setCurrency("CAD");

		filterCallBack([]);
		history.push("/");
	};

	return (
		<div>
			{/* Country Province filter*/}

			<Paper variant="outlined">
				<Card
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}>
					<CardHeader title="Country Filter" variant="outlined" />
					<CardContent>
						<CountryDropdown
							style={{ backgroundColor: "white", margin: 10 }}
							tabIndex={1000}
							value={country}
							onChange={(selectCountry) => {
								setCountry(selectCountry);
							}}
						/>
						<RegionDropdown
							style={{ backgroundColor: "white" }}
							tabIndex={1000}
							country={country}
							value={province}
							onChange={(selectProvince) => {
								setProvince(selectProvince);
							}}
						/>
					</CardContent>
				</Card>
			</Paper>

			{/* Product filter */}
			<Paper variant="outlined" style={{ marginTop: 20 }}>
				<Card
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}>
					<CardHeader title="Product Filter" />
					<RadioGroup row onChange={handleSelectProduct}>
						<FormControlLabel
							value="Students"
							control={<Radio />}
							label="Students"
						/>
						<FormControlLabel
							value="Refinancing"
							control={<Radio />}
							label="Refinancing"
						/>
					</RadioGroup>
				</Card>
			</Paper>

			{/* issueYear filter*/}
			<Paper variant="outlined" style={{ marginTop: 20 }}>
				<Card
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}>
					<CardHeader style={{ width: "40%" }} title="Issue Year Filter" />
					<Grid
						container
						spacing={2}
						alignItems="center"
						style={{ padding: 30 }}>
						<Grid item>2000</Grid>
						<Grid item xs>
							<Slider
								value={issueYear}
								min={2000}
								step={1}
								max={currentYear}
								scale={(x) => x ** 10}
								valueLabelFormat={issueYear}
								onChange={handleYearChange}
								valueLabelDisplay="auto"
							/>
						</Grid>
						<Grid item>{currentYear}</Grid>
					</Grid>
				</Card>
			</Paper>

			{/* currency filter*/}
			<Paper variant="outlined" style={{ marginTop: 20 }}>
				<Card
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}>
					<CardHeader title="Currency Filter" />
					<CardContent style={{ padding: 30 }}>
						<RadioGroup row onChange={handleSelectCurrency}>
							<FormControlLabel value="CAD" control={<Radio />} label="CAD" />
							<FormControlLabel value="USD" control={<Radio />} label="USD" />
							<FormControlLabel value="CHY" control={<Radio />} label="CHY" />
							<FormControlLabel value="JPY" control={<Radio />} label="JPY" />
						</RadioGroup>
					</CardContent>
				</Card>
			</Paper>

			<div
				style={{
					display: "flex",
					margin: 30,
					justifyContent: "space-between",
					alignItems: "center",
				}}>
				<Button variant="contained" color="primary" onClick={applyFilter}>
					Apply Filter
				</Button>
				<Button variant="contained" color="primary" onClick={cleanFilter}>
					Clean Filter
				</Button>
			</div>
		</div>
	);
}
