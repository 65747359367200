const OFFER = {
	LOWEST_PAYMENT_YEARS: 3, // 36 Months
	LOWEST_INTEREST_YEARS: 5, // 60Months
};

const FILE_TYPES = {
	PAYMENT_SENT_FILES: "PAYMENT_SENT_FILES",
	PAYMENT_RECEIVED_FILES: "PAYMENT_RECEIVED_FILES",
};
const TRANSACTION_TYPE = {
	AUTO: "auto",
	MANUAL: "manual",
	FUNDING: "funding",
};

const TRANSACTION_STATUS = {
	PENDING: "pending",
	APPROVED: "approved",
	REJECTED: "rejected",
	COMPLETED: "completed",
};

const STRONG_PASSWORD_SCORE = 4;
const REPAYMENT_MODE = {
	REGULAR: "regular",
	EXTRA: "extra",
	PAYOFF: "pay off",
	NA: "na",
};

const SCHEDULE_STATUS = {
	SCHEDULED: "scheduled",
	MISSED: "missed",
};

const MINIMUM_LATE_GRACE_DAY = 5;
const LOAN_ID_DOCUMENT = {
	SUFFIX_LENGTH: 8,
	STAR_LENGTH: 3,
};
exports.MALFORMED_UTF8_DATA = "Malformed UTF-8 data";
exports.PER_PAGE_RECORDS = 25;
exports.LOAN_ID_DOCUMENT = LOAN_ID_DOCUMENT;

exports.SCHEDULE_STATUS = SCHEDULE_STATUS;

exports.REPAYMENT_MODE = REPAYMENT_MODE;
exports.TRANSACTION_TYPE = TRANSACTION_TYPE;
exports.OFFER = OFFER;
exports.FILE_TYPES = FILE_TYPES;
exports.TRANSACTION_STATUS = TRANSACTION_STATUS;
exports.STRONG_PASSWORD_SCORE = STRONG_PASSWORD_SCORE;

exports.MINIMUM_LATE_GRACE_DAY = MINIMUM_LATE_GRACE_DAY;

exports.HTTP_RESPONSE_CODE = {
	SUCCESS: 200,
};

exports.ENV = {
	DEV: "development",
	UAT: "uat",
	PROD: "production",
};

exports.EMAIL = "EMAIL";
exports.PASSWORD = "PASSWORD";
