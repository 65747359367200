import AnalyticsAppBar from "./AppBar";
import AnalyticsChart from "./Chart";
import AnalyticsTable from "./Table";

//----------------------------------End of import--------------------------------------------------//

export default function CustomerAnalytics() {
	console.log(window.location.href + "=> Location");

	return (
		<div
			className="sidebar-content-container"
			style={{
				display: "flex",
				flexDirection: "column",
				width: "88%",
			}}>
			<AnalyticsAppBar />

			<div className="admin-card">
				<h3 style={{ textAlign: "center" }}>Under Development</h3>
				<AnalyticsChart />
				<AnalyticsTable />
			</div>
		</div>
	);
}
