import { Button, TextField } from "@material-ui/core";
import { useState } from "react";
import { deleteSoftData, deleteUser } from "../../../actions/users";
import { toast } from "react-toastify";
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
export default function DeleteCustomer(props) {
	const [email, setEmail] = useState("");
	const [openConfirmation, setOpenConfirmation] = useState(false);
	const [openHardConfirmation, setOpenHardConfirmation] = useState(false);



	const deleteCustomerData = async () => {
		setOpenHardConfirmation(false)
		const hardData = await deleteUser(email);
		if (hardData) {
			toast.success(hardData.message);
			setEmail("");
		}
	};
	const deleteSoft = async () => {
		setOpenConfirmation(false)

		const softData = await deleteSoftData(email);
		if (softData) {
			toast.success(softData.message);
			setEmail("");
		}
	}
	return (
		<>
			<div>
				<h3>Delete Customer </h3>
			</div>
			<div style={{ marginTop: "3%" }}>
				Email
				<TextField
					id="Email"
					value={email}
					onChange={(event) => setEmail(event.target.value)}
					style={{ width: "50%" }}
				/>
			</div>
			<div style={{ marginTop: "3%" }}>
				<Button
					style={{ padding: 10, width: "30%" }}
					disabled={email.length === 0}
					onClick={() => setOpenConfirmation(true)}>
					Soft Delete
				</Button>
				<Button
					style={{ padding: 10, width: "30%" }}
					disabled={email.length === 0}
					onClick={() => setOpenHardConfirmation(true)}>
					Hard Delete
				</Button>
			</div>
			<CModal
				show={openConfirmation}
				onClose={() => {
					setOpenConfirmation(false)
				}}
				color="dark">
				<CModalHeader closeButton>
					<CModalTitle className="m-2">Delete User</CModalTitle>
				</CModalHeader>
				<CModalBody>
					<p>Are you sure, you want to delete this user?</p>
				</CModalBody>
				<CModalFooter style={{ display: "block" }}>
					<ul
						style={{
							padding: 0,
							listStyleType: "none",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<li>
							<CButton
								className="ml-auto w-10  m-2"
								color="primary"
								onClick={deleteSoft}>
								Yes
							</CButton>
						</li>
						<li>
							<CButton
								className="ml-auto w-10  m-2"
								color="danger"
								onClick={() => {
									setOpenConfirmation(false)
								}}>
								No
							</CButton>
						</li>
					</ul>
				</CModalFooter>
			</CModal>

			<CModal
				show={openHardConfirmation}
				onClose={() => {
					setOpenHardConfirmation(false)
				}}
				color="dark">
				<CModalHeader closeButton>
					<CModalTitle className="m-2">Delete User</CModalTitle>
				</CModalHeader>
				<CModalBody>
					<p>Are you sure, you want to delete this user? (This will delete all the details of user)</p>
				</CModalBody>
				<CModalFooter style={{ display: "block" }}>
					<ul
						style={{
							padding: 0,
							listStyleType: "none",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<li>
							<CButton
								className="ml-auto w-10  m-2"
								color="primary"
								onClick={deleteCustomerData}>
								Yes
							</CButton>
						</li>
						<li>
							<CButton
								className="ml-auto w-10  m-2"
								color="danger"
								onClick={() => {
									setOpenHardConfirmation(false)
								}}>
								No
							</CButton>
						</li>
					</ul>
				</CModalFooter>
			</CModal>
		</>
	);
}
