import axios from "axios";
import { headers } from "../../reusable/headers";

export const getPermissions = async () => {
	try {
		const result = await axios.get(
			`${process.env.REACT_APP_API_HOST}/V1/admin/all-permissions`,
			{ headers: headers() }
		);
		return result.data;
	} catch (e) {
		console.log(e.message);
	}
};
