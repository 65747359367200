import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import { Typography } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

//------------------------------------------End of Import ---------------------------------------------------//

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
		marginBottom: "15px",
	},
	parentTextStyle: {
		padding: "10px 25px",
		verticalAlign: "center",
		margin: "5px 5px",
		cursor: "pointer",
		"&:hover": {
			background: "#9e9e9e",
			verticalAlign: "center",
			cursor: "pointer",
		},
	},
	highlightStyle: {
		backgroundColor: "#9e9e9e",
		padding: "10px 25px",
		verticalAlign: "center",
		margin: "5px 5px",
		cursor: "pointer",
	},
}));

export default function SideBar() {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const [highlight, setHighlight] = useState({
		portfolio: true,
		operational: false,
		risks: false,
		behavioral: false,
		analytics: false,
		custom: false,
	});
	useEffect(() => {
		if (location.pathname === "/report/portfolio") {
			setHighlight({
				portfolio: true,
				operational: false,
				risks: false,
				behavioral: false,
			});
		}
		if (location.pathname === "/report/operational") {
			setHighlight({
				portfolio: false,
				operational: true,
				risks: false,
				behavioral: false,
			});
		}
		if (location.pathname === "/report/risks") {
			setHighlight({
				portfolio: false,
				operational: false,
				risks: true,
				behavioral: false,
			});
		}
		if (location.pathname === "/report/behavioral") {
			setHighlight({
				portfolio: false,
				operational: false,
				risks: false,
				behavioral: true,
			});
		}
		if (location.pathname === "/report/customeranalytics") {
			setHighlight({
				portfolio: false,
				operational: false,
				risks: false,
				behavioral: false,
				analytics: true,
			});
		}
		if (location.pathname === "/report/custom") {
			setHighlight({
				portfolio: false,
				operational: false,
				risks: false,
				behavioral: false,
				analytics: false,
				custom: true,
			});
		}
	}, [location.pathname]);

	return (
		<div className="reports-side-menu">
			<Paper
				style={{
					background: "#fff",
					padding: "10px 0px",
					fontFamily: "sans-serif",
				}}>
				<Typography
					onClick={() => {
						history.push("/report/portfolio");
					}}
					className={
						highlight.portfolio
							? classes.highlightStyle + " active-tab"
							: classes.parentTextStyle
					}>
					Portfolio
				</Typography>
				<Typography
					onClick={() => {
						history.push("/report/operational");
					}}
					className={
						highlight.operational
							? classes.highlightStyle + " active-tab"
							: classes.parentTextStyle
					}>
					Operational
				</Typography>
				<Typography
					onClick={() => {
						history.push("/report/risks");
					}}
					className={
						highlight.risks
							? classes.highlightStyle + " active-tab"
							: classes.parentTextStyle
					}>
					Risks
				</Typography>
				<Typography
					onClick={() => {
						history.push("/report/behavioral");
					}}
					className={
						highlight.behavioral
							? classes.highlightStyle + " active-tab"
							: classes.parentTextStyle
					}>
					Behavioral
				</Typography>

				<Typography
					onClick={() => {
						history.push("/report/customeranalytics");
					}}
					className={
						highlight.analytics
							? classes.highlightStyle + " active-tab"
							: classes.parentTextStyle
					}>
					Analytics
				</Typography>

				<Typography
					onClick={() => {
						history.push("/report/custom");
					}}
					className={
						highlight.custom
							? classes.highlightStyle + " active-tab"
							: classes.parentTextStyle
					}>
					Custom
				</Typography>
			</Paper>
		</div>
	);
}
