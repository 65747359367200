import axios from "axios";
import { headers } from "../../reusable/headers";
export async function getModelAllFiles() {
	const result = await axios.get(
		`${process.env.REACT_APP_API_HOST}/V1/admin/model/files`,
		{ headers: headers() }
	);
	return result.data;
}
export async function decryptfileContent(fileName) {
	const result = await axios.post(
		`${process.env.REACT_APP_API_HOST}/V1/admin/model/decrypt`,
		{ fileName: fileName },
		{ headers: headers() }
	);

	return result.data;
}

export async function getModelFiles(appId) {
	const result = await axios.get(
		`${process.env.REACT_APP_API_HOST}/V1/admin/model/files/${appId}`,
		{ headers: headers() }
	);
	return result.data;
}
