import { useState } from "react";
import excel from "../../../../assets/icons/Excel2.png";
import { OperationalStyle } from "../Style/Style";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from "@material-ui/core";
import { ReportFolioStyle } from "../Style/Style";
//------------------------------------------End of Import ---------------------------------------------------//

export default function OperationalAppBar(props) {
	const style = OperationalStyle();
	const classes = ReportFolioStyle();
	const [excelExport, setExcelExport] = useState([]);
	const handleChange = (e) => {
		props.setTerm(e.target.value);
	};
	const handleDateChange = (date) => {
		props.setFromDate(date);
	};
	const toDateChange = (date) => {
		props.setToDate(date);
	};

	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	const exportToCSV = (apiData, fileName) => {
		var excelData = [];
		props.operationalTableData &&
			props.operationalTableData.forEach((item) => {
				let obj = {};
				obj.Date = moment?.(item.created_at).format("YYYY-MM-DD");
				obj.Active = item.activen;
				obj.Rejected = item.rejected;
				obj.Progress = item.progress;
				excelData.push(obj);
			});
		// console.log(excelData);
		setExcelExport(excelData);
		const ws = XLSX.utils.json_to_sheet(excelData);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName + fileExtension);
	};

	return (
		<div>
			<AppBar
				position="static"
				style={{
					background: "#DADFE4",
					margin: "10px 0px",
					fontFamily: "sans-serif",
					width: "fit-content",
					minWidth: "100%",
				}}>
				<Toolbar>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<div>
							<FormControl>
								<NativeSelect
									value={props.term}
									onChange={handleChange}
									displayEmpty
									inputProps={{ "aria-label": "Without label" }}
									style={{ margin: "0px 10px", width: "max-content" }}>
									<option value={"Daily"}>Daily</option>
									<option value={"Weekly"}>Weekly</option>
									<option value={"Monthly"}>Monthly</option>
									<option value={"Quaterly"}>Quaterly</option>
									<option value={"Annually"}>Annually</option>
								</NativeSelect>
							</FormControl>
						</div>

						<Typography className={style.fromStyle}>From</Typography>

						<KeyboardDatePicker
							autoOk={true}
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							id="date-picker-inline"
							value={props.fromDate}
							onChange={handleDateChange}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							style={{
								margin: "0px 10px 0px 10px",
								outline: "none",
								width: "max-content",
							}}
						/>
						<Typography style={{ color: "#000000DE" }}>To</Typography>
						<KeyboardDatePicker
							autoOk={true}
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							id="date-picker-inline"
							value={props.toDate}
							onChange={toDateChange}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							style={{ margin: "0px 10px 0px 10px", outline: "none" }}
						/>
					</MuiPickersUtilsProvider>
					<div style={{ display: "flex" }}>
						<div>
							<img
								style={{
									width: "20px",
									height: "20px",
									marginTop: "5px",
									marginLeft: "30px",
								}}
								src={excel}
								alt=''
							/>
						</div>

						<button
							className={classes.exportButtonStyle}
							onClick={(e) => exportToCSV(excelExport, "Operational")}>
							EXPORT
						</button>
					</div>
				</Toolbar>
			</AppBar>
		</div>
	);
}
