import AES from "crypto-js/aes";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";

function headers() {
	let headerOpts = {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
	};
	headerOpts["st"] = sessionStorage.getItem("_st");
	headerOpts["Source"] = getEncryptedAndEncodedWindowPath();
	return headerOpts;
}

function getEncryptedAndEncodedWindowPath() {
	const encrypted = AES.encrypt(
		window.location.pathname,
		process.env.REACT_APP_HEADER_SOURCE_SECRET_KEY
	).toString();
	const wordArray = Utf8.parse(encrypted);
	const base64 = Base64.stringify(wordArray);
	return base64;
}

export { headers };
