import { OperationalStyle } from "../Style/Style";
import {
	Table,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
//----------------------------------End of import--------------------------------------------------//

export default function Data(props) {
	const portfolioStyle = OperationalStyle();

	return (
		<div className="admin-card custom-form-table">
			<div style={{ marginBottom: "20px" }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography style={{ fontWeight: "700" }}>
									Average interests:
								</Typography>
								<Typography>
									<NumberFormat
										// value={`${(
										//   props.reportInterestData.totalIntrest /
										//   props.reportInterestData.totalLoans
										// ).toFixed(2)}`}
										value={`${(
											props.rateSum / props.reportInterestData.length
										).toFixed(2)}`}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										renderText={(value) => <div> {value}</div>}
									/>
								</Typography>
							</TableCell>
							<TableCell>
								<Typography style={{ fontWeight: "700" }}>
									Total interests:
								</Typography>
								<Typography>
									<NumberFormat
										// value={props.reportInterestData.totalIntrest?.toFixed(2)}
										value={props.rateSum.toFixed(2)}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										renderText={(value) => <div> {value}</div>}
									/>
								</Typography>
							</TableCell>
							<TableCell>
								<Typography style={{ fontWeight: "700" }}>
									Total issued Loans:
								</Typography>
								{/* <Typography>{props.reportInterestData.totalLoans}</Typography> */}
								<Typography>{props.reportInterestData.length}</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</div>

			<div style={{ marginBottom: "20px" }}>
				<Typography className={portfolioStyle.HeadingBorderStyle}>
					Average of FICO score
				</Typography>

				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: "35%" }}>
								<Typography>Approved:</Typography>
								<Typography>{`${(
									props.averagValue.sumOfFico /
									props.reportAcceptedScoreData.length
								).toFixed(2)}`}</Typography>
							</TableCell>
							<TableCell>
								<Typography>Rejected:</Typography>
								{/* <Typography>{props.rejectedSumValue}</Typography> */}
								<Typography>{`${(
									props.rejectedSumValue.sumOfFico /
									props.reportRejectedScoreData.length
								).toFixed(2)}`}</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</div>

			<div style={{ marginBottom: "20px" }}>
				<Typography className={portfolioStyle.HeadingBorderStyle}>
					Average of Q score
				</Typography>

				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: "35%" }}>
								<Typography>Approved:</Typography>
								<Typography>{`${(
									props.averagValue.sumOfQ /
									props.reportAcceptedScoreData.length
								).toFixed(2)}`}</Typography>
							</TableCell>
							<TableCell>
								<Typography>Rejected:</Typography>
								{/* <Typography>{props.rejectedSumValue}</Typography> */}
								<Typography>{`${(
									props.rejectedSumValue.sumOfQ /
									props.reportRejectedScoreData.length
								).toFixed(2)}`}</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</div>

			<div style={{ marginBottom: "20px" }}>
				<Typography className={portfolioStyle.HeadingBorderStyle}>
					Count
				</Typography>

				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: "35%" }}>
								<Typography>Count:</Typography>
								<Typography>{`${
									props.reportAcceptedScoreData.length +
									props.reportRejectedScoreData.length
								}`}</Typography>
							</TableCell>
							<TableCell style={{ width: "30%" }}>
								<Typography>Approved:</Typography>
								<Typography>{props.reportAcceptedScoreData.length}</Typography>
							</TableCell>
							<TableCell>
								<Typography>Rejected:</Typography>
								<Typography>{props.reportRejectedScoreData.length}</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</div>

			<div style={{ marginBottom: "20px" }}>
				<Typography className={portfolioStyle.HeadingBorderStyle}>
					Percentage of FICO Score and Q Score %
				</Typography>

				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: "35%" }}>
								<Typography>Approved %:</Typography>
								<Typography>{`${(
									((props.reportAcceptedScoreData.length +
										props.reportRejectedScoreData.length) /
										props.reportAcceptedScoreData.length) *
									100
								).toFixed(2)}`}</Typography>
							</TableCell>
							<TableCell>
								<Typography>Rejected %:</Typography>
								<Typography>{`${(
									props.reportRejectedScoreData.length * 100
								).toFixed(2)}`}</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</div>

			<div style={{ marginBottom: "20px" }}>
				<Typography className={portfolioStyle.HeadingBorderStyle}>
					No of Loans
				</Typography>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: "35%" }}>
								<Typography>Personal:</Typography>
								<Typography>{props.loans.personalLoan.length}</Typography>
							</TableCell>
							<TableCell style={{ width: "30%" }}>
								<Typography>Student:</Typography>
								<Typography>{props.loans.studentLoan.length}</Typography>
							</TableCell>
							<TableCell>
								<Typography>Refinancing:</Typography>
								<Typography>{props.loans.refinancingLoan.length}</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</div>

			<div style={{ marginBottom: "20px" }}>
				<Typography className={portfolioStyle.HeadingBorderStyle}>
					Volume of Loans
				</Typography>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: "35%" }}>
								<Typography>Personal:</Typography>
								<Typography>
									<NumberFormat
										value={props.sumOfLoans.sumOfPersonalLoan}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										renderText={(value) => <div>{value}</div>}
									/>
								</Typography>
							</TableCell>
							<TableCell style={{ width: "30%" }}>
								<Typography>Student:</Typography>
								<Typography>
									<NumberFormat
										value={props.sumOfLoans.sumOfStudentLoan}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										renderText={(value) => <div>{value}</div>}
									/>
								</Typography>
							</TableCell>
							<TableCell>
								<Typography>Refinancing:</Typography>
								<Typography>
									<NumberFormat
										value={props.sumOfLoans.sumOfRefinancingloan}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										renderText={(value) => <div>{value}</div>}
									/>
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</div>

			<div style={{ marginBottom: "20px" }}>
				<Typography className={portfolioStyle.HeadingBorderStyle}>
					Average Yield Loans %
				</Typography>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: "35%" }}>
								<Typography>Personal %:</Typography>
								<Typography>
									{(props.sumOfLoans.personalSumOfColumnG * 100).toFixed(2)}
								</Typography>
							</TableCell>
							<TableCell style={{ width: "30%" }}>
								<Typography>Student %:</Typography>
								<Typography>
									{(props.sumOfLoans.studentSumOfColumnG * 100).toFixed(2)}
								</Typography>
							</TableCell>
							<TableCell>
								<Typography>Refinancing %:</Typography>
								<Typography>
									{(props.sumOfLoans.refinancingSumOfColumnG * 100).toFixed(2)}
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</div>

			<div style={{ marginBottom: "20px" }}>
				<Typography style={{ fontWeight: "700", color: "black" }}>
					Portfolio Average Yield %
				</Typography>
				<Typography>{`${(
					(props.portfolioAverageYield.personalsumOfColumnD +
						props.portfolioAverageYield.studentSumOfColumnD +
						props.portfolioAverageYield.refinancingSumOfColumnD) *
					100
				).toFixed(2)}`}</Typography>
			</div>

			<div>
				<Typography className={portfolioStyle.HeadingBorderStyle}>
					Portfolio Mix %
				</Typography>
				<Table style={{ width: "fit content" }}>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: "35%" }}>
								<Typography>Personal %:</Typography>
								<Typography>{`${(
									(props.sumOfLoans.sumOfPersonalLoan /
										(props.sumOfLoans.sumOfPersonalLoan +
											props.sumOfLoans.sumOfStudentLoan +
											props.sumOfLoans.sumOfRefinancingloan)) *
									100
								).toFixed(2)}`}</Typography>
							</TableCell>
							<TableCell style={{ width: "30.3%" }}>
								<Typography>Student %:</Typography>
								<Typography>{`${(
									(props.sumOfLoans.sumOfStudentLoan /
										(props.sumOfLoans.sumOfPersonalLoan +
											props.sumOfLoans.sumOfStudentLoan +
											props.sumOfLoans.sumOfRefinancingloan)) *
									100
								).toFixed(2)}`}</Typography>
							</TableCell>
							<TableCell style={{ width: "27%" }}>
								<Typography>Refinancing %:</Typography>
								<Typography>{`${(
									(props.sumOfLoans.sumOfRefinancingloan /
										(props.sumOfLoans.sumOfPersonalLoan +
											props.sumOfLoans.sumOfStudentLoan +
											props.sumOfLoans.sumOfRefinancingloan)) *
									100
								).toFixed(2)}`}</Typography>
							</TableCell>
							<TableCell style={{ width: "20%" }}>
								<Typography>Total %:</Typography>
								<Typography>{`${(
									(props.sumOfLoans.sumOfPersonalLoan /
										props.sumOfLoans.totalLoan +
										props.sumOfLoans.sumOfStudentLoan /
											props.sumOfLoans.totalLoan +
										props.sumOfLoans.sumOfRefinancingloan /
											props.sumOfLoans.totalLoan) *
									100
								).toFixed(2)}`}</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</div>
		</div>
	);
}
