export default function Behavioral() {
	return (
		<div className="sidebar-content-container" style={{ width: "88%" }}>
			<div className="admin-card">
				<h3
					style={{
						textAlign: "center",
						marginTop: "10px",
						marginBottom: "10px",
					}}>
					Under Development
				</h3>
			</div>
		</div>
	);
}
