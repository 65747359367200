import React from "react";
import PAPGeneration from "./page/admin/banking/papGeneration";
import DeleteCustomer from "./page/admin/deleteCustomer/deleteCustomer";
import UploadFile from "./page/admin/documents/uploadFile";
import FirestoreDecrypt from "./page/admin/firestoreDecrypt/firestoreDecrypt";
import ModelDecrypt from "./page/admin/model/model";


const CustomerUsers = React.lazy(() => import("./page/users/customerUsers"));
const FormCustomerUsers = React.lazy(() =>
	import("./page/users/formCustomerUsers")
);
const AdminUsers = React.lazy(() => import("./page/admin/adminUsers"));
const FormAdminUsers = React.lazy(() => import("./page/admin/formAdminUsers"));
const Roles = React.lazy(() => import("./page/admin/roles/listRoles"));
const FormRoles = React.lazy(() => import("./page/admin/roles/formRoles"));
const Applications = React.lazy(() =>
	import("./page/customers/applicationList")
);
const InformationCard = React.lazy(() =>
	import("./components/informationcard")
);
const ProductsTable = React.lazy(() =>
	import("./page/admin/products/productstable")
);
const FormAdminProducts = React.lazy(() =>
	import("./page/admin/products/formAdminProducts")
);
const PurposeTable = React.lazy(() =>
	import("./page/admin/purpose/purposeList")
);
const FormAdminPurpose = React.lazy(() =>
	import("./page/admin/purpose/formAdminPurpose")
);
const DecisionRules = React.lazy(() =>
	import("./page/admin/decisionrules/decisionrules")
);
const GlobalSettings = React.lazy(() =>
	import("./page/admin/settings/globalSetting")
);

const Documents = React.lazy(() => import("./page/admin/documents/documents"));
const Banking = React.lazy(() => import("./page/admin/banking/banking"));
const Messages = React.lazy(() => import("./page/admin/messages/messages"));
const FormMessage = React.lazy(() =>
	import("./page/admin/messages/formMessage")
);
const Blacklist = React.lazy(() => import("./page/admin/blacklist/blacklist"));
const BankingTab = React.lazy(() => import("./page/admin/banking/bankingTab"));
const EmailNotification = React.lazy(() =>
	import("./page/admin/emailtemplates/emailtemplates")
);

const Builder = React.lazy(() => import("./page/admin/urlBuilder/UrlBuilder"));

const Simluation = React.lazy(() =>
	import("./page/admin/simulation/simulation")
);
const CreateSimulation = React.lazy(() =>
	import("./page/admin/simulation/create")
);
const EditSimulation = React.lazy(() => import("./page/admin/simulation/edit"));

const Logs = React.lazy(() => import("./page/admin/logs/logs"));

const routes = [
	{
		path: "/customer",
		exact: true,
		name: "Applications",
		component: Applications,
	},
	{
		path: "/admin/customers",
		exact: true,
		name: "Customer Users",
		component: CustomerUsers,
	},
	{
		path: "/origination",
		exact: true,
		name: "Applications",
		component: Applications,
	},
	{
		path: "/underwriting",
		exact: true,
		name: "Applications",
		component: Applications,
	},
	{
		path: "/funding",
		exact: true,
		name: "Applications",
		component: Applications,
	},
	{
		path: "/servicing",
		exact: true,
		name: "Applications",
		component: Applications,
	},
	{
		path: "/collections",
		exact: true,
		name: "Applications",
		component: Applications,
	},
	{
		path: "/completed",
		exact: true,
		name: "Applications",
		component: Applications,
	},
	{
		path: "/archived",
		exact: true,
		name: "Applications",
		component: Applications,
	},
	{ path: "/banking", exact: true, name: "Banking", component: BankingTab },
	{
		path: "/customer/view",
		exact: true,
		name: "Application View",
		component: InformationCard,
	},
	{
		path: "/archived",
		exact: true,
		name: "Archived Applications",
		component: Applications,
	},
	{
		path: "/users",
		exact: true,
		name: "Customer Users",
		component: CustomerUsers,
	},
	{
		path: "/users/create",
		exact: true,
		name: "Create Customer Users",
		component: FormCustomerUsers,
	},
	{
		path: "/users/edit",
		exact: true,
		name: "Edit Customer Users",
		component: FormCustomerUsers,
	},
	{ path: "/admin", exact: true, name: "Admin Users", component: AdminUsers },
	{ path: "/admin/users", exact: true, name: "Users", component: AdminUsers },
	{
		path: "/admin/users/create",
		exact: true,
		name: "Create Users",
		component: FormAdminUsers,
	},
	{
		path: "/admin/users/edit",
		exact: true,
		name: "Edit Users",
		component: FormAdminUsers,
	},
	{ path: "/admin/roles", exact: true, name: "Users", component: Roles },
	{
		path: "/admin/roles/create",
		exact: true,
		name: "Create Users",
		component: FormRoles,
	},
	{
		path: "/admin/roles/edit",
		exact: true,
		name: "Edit Users",
		component: FormRoles,
	},
	{
		path: "/admin/customer",
		exact: true,
		name: "Applications",
		component: Applications,
	},
	{
		path: "/admin/customer/view",
		exact: true,
		name: "Applications",
		component: InformationCard,
	},
	{
		path: "/admin/products",
		exact: true,
		name: "Products",
		component: ProductsTable,
	},
	{
		path: "/admin/products/create",
		exact: true,
		name: "Create Products",
		component: FormAdminProducts,
	},
	{
		path: "/admin/products/edit",
		exact: true,
		name: "Edit Products",
		component: FormAdminProducts,
	},
	{
		path: "/admin/purpose",
		exact: true,
		name: "Purpose",
		component: PurposeTable,
	},
	{
		path: "/admin/purpose/create",
		exact: true,
		name: "Create Purpose",
		component: FormAdminPurpose,
	},
	{
		path: "/admin/purpose/edit",
		exact: true,
		name: "Edit Purpose",
		component: FormAdminPurpose,
	},
	{
		path: "/admin/archived",
		exact: true,
		name: "Archived Applications",
		component: Applications,
	},
	{
		path: "/admin/rules",
		exact: true,
		name: "Decision Rules",
		component: DecisionRules,
	},
	{
		path: "/admin/generatepap",
		exact: true,
		name: "Generate PAP",
		component: PAPGeneration,
	},
	{
		path: "/admin/settings",
		exact: true,
		name: "Global Settings",
		component: GlobalSettings,
	},
	{ path: "/admin/banking", exact: true, name: "Banking", component: Banking },
	{
		path: "/admin/documents",
		exact: true,
		name: "Documents",
		component: Documents,
	},
	{
		path: "/admin/blacklists",
		exact: true,
		name: "Blacklist",
		component: Blacklist,
	},
	{
		path: "/admin/messages",
		exact: true,
		name: "Messages",
		component: Messages,
	},
	{
		path: "/admin/messages/create",
		exact: true,
		name: "Create Message",
		component: FormMessage,
	},
	{
		path: "/admin/email-notification",
		exact: true,
		name: "Email Notification",
		component: EmailNotification,
	},

	{
		path: "/admin/urlbuilder",
		exact: true,
		name: "Url Builder",
		component: Builder,
	},
	{
		path: "/admin/simulation",
		exact: true,
		name: "Simulation",
		component: Simluation,
	},
	{
		path: "/admin/simulation/create",
		exact: true,
		name: "Create Simulation",
		component: CreateSimulation,
	},
	{
		path: "/admin/simulation/edit",
		exact: true,
		name: "Edit Simulation",
		component: EditSimulation,
	},
	{
		path: "/admin/logs",
		exact: true,
		name: "Logs",
		component: Logs,
	},
	{
		path: "/admin/models",
		exact: true,
		name: "Models Decrypt",
		component: ModelDecrypt,
	},
	{
		path: "/admin/firestoreDecrypt",
		exact: true,
		name: "Firestore Decrypt",
		component: FirestoreDecrypt,
	},
	{
		path: "/admin/uploadFile",
		exact: true,
		name: "Upload File",
		component: UploadFile,
	},
	{
		path: "/admin/deleteCustomer",
		exact: true,
		name: "Delete Customer",
		component: DeleteCustomer,
	},
	//   { path: '/dashboard', name: 'Dashboard', component: Dashboard },
	//   { path: '/dashboard', name: 'Dashboard', component: Dashboard },
];

export default routes;
