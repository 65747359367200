import { CAlert } from "@coreui/react";
import { useState } from "react";
import { uploadGlobalFilesData } from "../../../services/application/application";
import { checkFileBeforeUpload } from "../../../utils/fileCheck";

export default function UploadFile(props) {
	const [imageFiles, setImageFiles] = useState();
	const [appId, setappId] = useState("");
	const [filepath, setFilepath] = useState("");
	const [error, setError] = useState("");

	const handleImage = (event) => {
		event.preventDefault();
		setImageFiles(event.target.files[0]);
	};
	const uploadGlobalFile = async () => {
		const res = checkFileBeforeUpload(imageFiles);
		if (!res.success) {
			return res;
		}
		console.log(res);
		let uploadData = new FormData();
		uploadData.append("file", imageFiles);
		uploadData.append("id", appId);
		uploadData.append("filepath", filepath);
		setError("Wait 30 seconds");
		const uploadGlobalFile = await uploadGlobalFilesData(uploadData);
		if (uploadGlobalFile.success === true) {
			setError("FILE UPLOADED SUCCESSFULLY");
			setappId("");
			setImageFiles("");
			setFilepath("");
		} else {
			setError("SOMETHING WENT WRONG");
		}
	};
	const submitFile = () => {
		if (!appId || !imageFiles) {
			setError("AppId/File is Required");
			return;
		} else {
			uploadGlobalFile();
		}
	};
	return (
		<>
			<h1>Bucket File Replace Tool</h1>
			<small>
				This tool doesn't update database values, it only replaces the files on
				bucket and If file doesn't exist it will create new file on bucket
			</small>
			<div class="mb-3 row">
				<label for="inputPassword" class="col-sm-2 col-form-label">
					AppId
				</label>
				<div class="col-sm-10">
					<input
						type="text"
						class="form-control"
						id="inputPassword"
						value={appId}
						onChange={(e) => setappId(e.target.value)}
					/>
					<small>
						AppId is used to search for File in specified location with prefix
					</small>
				</div>
			</div>
			<div class="mb-3 row">
				<label for="inputPassword" class="col-sm-2 col-form-label">
					File Path
				</label>
				<div class="col-sm-10">
					<input
						type="text"
						class="form-control"
						id="inputPassword"
						value={filepath}
						onChange={(e) => setFilepath(e.target.value)}
					/>
					<small>
						If File doesn't exist with the above appId , a new File will be
						created
					</small>
				</div>
			</div>
			<div class="mb-3 row">
				<label for="inputPassword" class="col-sm-2 col-form-label">
					New File
				</label>
				<div class="col-sm-10">
					<input
						type="file"
						class="form-control"
						id="inputPassword"
						onChange={(e) => handleImage(e)}
						aria-describedby="inputGroupFileAddon03"
						name="fileName"
					/>
				</div>
			</div>
			<div class="mb-3 row">
				<div class="col-sm-10">
					<input
						type="submit"
						class="form-control"
						id="inputPassword"
						onClick={submitFile}
					/>
				</div>
			</div>
			{error.length ? <CAlert color="primary">{error}</CAlert> : ""}
		</>
	);
}
