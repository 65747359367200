import React, { useState } from "react";
import axios from "axios";
import { generateManualPAP } from "../../../actions/users";

const styles = {
	formContainer: {
		width: "300px",
		margin: "0 auto",
		padding: "20px",
		border: "1px solid #ccc",
		borderRadius: "5px",
	},
	label: {
		display: "block",
		marginBottom: "5px",
	},
	input: {
		width: "100%",
		padding: "8px",
		marginBottom: "10px",
		border: "1px solid #ccc",
		borderRadius: "5px",
	},
	button: {
		display: "block",
		width: "100%",
		padding: "10px",
		backgroundColor: "#007bff",
		color: "#fff",
		border: "none",
		borderRadius: "5px",
		cursor: "pointer",
	},
	buttonHover: {
		backgroundColor: "#0056b3",
	},
};

const ManualPAPForm = () => {
	const [repaymentMode, setRepaymentMode] = useState("regular");
	const [startDate, setStartDate] = useState("");
	const [loanIds, setLoanIds] = useState("");

	const [endDate, setEndDate] = useState("");

	const handleRepaymentModeChange = (event) => {
		setRepaymentMode(event.target.value);
	};

	const handleStartDateChange = (event) => {
		setStartDate(event.target.value);
	};
	const handleLoanIdChange = (event) => {
		setLoanIds(event.target.value);
	};
	const handleEndDateChange = (event) => {
		setEndDate(event.target.value);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {
			// Make the API request here
			const response = await generateManualPAP(repaymentMode, startDate , loanIds);
			console.log(response);
			const blob = new Blob([response.data], { type: "text/plain" });

			// Create a temporary URL to download the file
			const url = URL.createObjectURL(blob);

			// Create a link element and simulate click to initiate the download
			const link = document.createElement("a");
			link.href = url;
			link.download = "file.txt";
			document.body.appendChild(link);
			link.click();

			// Clean up the temporary URL
			URL.revokeObjectURL(url);

			// Handle the response data as needed
			console.log("API Response:", response.data);
		} catch (error) {
			// Handle errors if the API request fails
			console.error("API Error:", error.message);
		}
	};

	return (
		<form style={styles.formContainer} onSubmit={handleSubmit}>
			<div>
				<label style={styles.label} htmlFor="repaymentMode">
					Repayment Mode:
				</label>
				<select
					style={styles.select}
					id="repaymentMode"
					value={repaymentMode}
					onChange={handleRepaymentModeChange}>
					<option value="regular">Regular</option>
					<option value="extra">Extra</option>
					<option value="pay off">Pay Off</option>
					<option value="other">Regular + Extra + Pay Off</option>

				</select>
			</div>
			<div>
				<label style={styles.label} htmlFor="startDate">
					Date:
				</label>
				<input
					style={styles.input}
					type="date"
					id="startDate"
					value={startDate}
					onChange={handleStartDateChange}
				/>
			</div>
			<div>
				<label style={styles.label} htmlFor="startDate">
					Application Ids:
				</label>
				<input
					style={styles.input}
					type="text"
					id="loanIds"
					value={loanIds}
					onChange={handleLoanIdChange}
					placeholder="Enter comma separated loan ids"
				/>
			</div>
			<button
				style={styles.button}
				type="submit"
				onMouseOver={(e) => {
					e.target.style.backgroundColor = styles.buttonHover.backgroundColor;
				}}
				onMouseOut={(e) => {
					e.target.style.backgroundColor = styles.button.backgroundColor;
				}}>
				Submit
			</button>
		</form>
	);
};

export default ManualPAPForm;
