import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import TableContainer from "@material-ui/core/TableContainer";
//----------------------------------End of import--------------------------------------------------//

export default function PortfolioTable(props) {
	return (
		<div style={{ display: "flex" }}>
			<TableContainer>
				<Table style={{ marginTop: "30px" }} id="table">
					<TableHead>
						<TableRow style={{ background: "#cfd8dc" }}>
							<TableCell style={{ fontWeight: "780", width: "fit-content" }}>
								Date
							</TableCell>
							<TableCell style={{ fontWeight: "780" }}>Active Loans</TableCell>
							<TableCell style={{ fontWeight: "780" }}>Portfolio</TableCell>
						</TableRow>
					</TableHead>
					{props.reportData.map((item, index) => (
						<TableBody>
							<TableRow>
								<TableCell>{item.created_on}</TableCell>
								<TableCell>{item.active_loans}</TableCell>
								<TableCell>
									<NumberFormat
										value={item.portfolio}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
										renderText={(value) => <div>{value}</div>}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					))}
				</Table>
			</TableContainer>
		</div>
	);
}
